import React from 'react'

import confetti from 'canvas-confetti'
import { X as XIcon } from 'lucide-react'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'

import { useOnborda } from './onborda-context'
import { getArrowStyle, StyledArrow } from './onborda-styles'
import { CardComponentProps, Step } from './types'

export const DefaultCard = ({
  currentStep,
  nextStep,
  prevStep,
  arrow,
  steps,
}: CardComponentProps) => {
  const { closeOnborda } = useOnborda()
  const handleConfetti = () => {
    closeOnborda()
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    })
  }

  return (
    <Card
      sx={{
        backgroundColor: 'background.paper',
        pt: 0,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'primary.main',
      }}
    >
      <CardHeader
        sx={{ p: 2 }}
        action={
          <IconButton onClick={() => closeOnborda()} size="small">
            <XIcon size={20} />
          </IconButton>
        }
        title={
          <Box display="flex" alignItems="center">
            {steps[currentStep]?.icon}
            <Typography variant="h6" component="span" sx={{ ml: 1 }}>
              {steps[currentStep]?.title}
            </Typography>
          </Box>
        }
        subheader={
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: 'center', mt: 1 }}
          >
            {currentStep + 1} of {steps.length}
          </Typography>
        }
      />

      <Box sx={{ mb: 2 }}>
        <LinearProgress
          variant="determinate"
          value={((currentStep + 1) / steps.length) * 100}
          sx={{ borderRadius: 0 }}
        />
      </Box>
      <CardContent sx={{ py: 0 }}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            borderRadius: 1,
            flexGrow: 1,
            p: 2,
          }}
        >
          <Typography>{steps[currentStep]?.content}</Typography>
        </Box>
      </CardContent>
      <CardActions disableSpacing>
        {steps[currentStep]?.showControls && (
          <Box display="flex" justifyContent="space-between" width="100%">
            {currentStep !== 0 && (
              <Button variant="contained" onClick={() => prevStep()}>
                Previous
              </Button>
            )}
            {currentStep + 1 !== steps.length ? (
              <Button
                variant="contained"
                onClick={() => nextStep()}
                sx={{ ml: 'auto' }}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleConfetti()}
                sx={{ ml: 'auto' }}
              >
                🎉 Finish!
              </Button>
            )}
          </Box>
        )}
      </CardActions>
      <Box sx={{ color: 'background.paper' }}>{arrow}</Box>
    </Card>
  )
}

export const CardArrow = ({ steps }: { steps: Step[] }) => {
  const { currentStep } = useOnborda()

  return (
    <StyledArrow
      viewBox="0 0 54 54"
      data-name="onborda-arrow"
      style={getArrowStyle(steps[currentStep]?.side as any)}
    >
      <path className="arrow-fill" d="M27 27L2 2L2 52Z" />
      <path className="arrow-primary" d="M1 2L27 27" />
      <path className="arrow-primary" d="M1 52L27 27" />
      <path className="arrow-secondary" d="M2 2L2 52" />
    </StyledArrow>
  )
}
