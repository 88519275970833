import type { FC, ReactNode } from 'react'
import { useState, useCallback, memo } from 'react'

import NextLink from 'next/link'
import { useRoot } from 'store/useRoot'

import type { ListItemProps, SxProps, Theme } from '@mui/material'
import { Box, Button, Collapse, ListItem } from '@mui/material'

import { ChevronDown as ChevronDownIcon } from 'icons/chevron-down'
import { ChevronRight as ChevronRightIcon } from 'icons/chevron-right'

// Root store type (replace with actual type if available)
interface RootStore {
  disableNav: boolean
  [key: string]: any
}

interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean
  children?: ReactNode
  chip?: ReactNode
  depth: number
  icon?: ReactNode
  info?: ReactNode
  open?: boolean
  path?: string
  title: string
  id?: string
  disabled?: boolean
  onDisabledLinkClick?: () => void
}

interface BranchItemProps extends Omit<ListItemProps, 'title'> {
  active?: boolean
  children?: ReactNode
  depth: number
  icon?: ReactNode
  info?: ReactNode
  open?: boolean
  title: string
  id?: string
  disabled?: boolean
  root: RootStore
}

interface LeafItemProps extends Omit<ListItemProps, 'title'> {
  active?: boolean
  chip?: ReactNode
  depth: number
  icon?: ReactNode
  info?: ReactNode
  path?: string
  title: string
  id?: string
  disabled?: boolean
  onDisabledLinkClick?: () => void
  root: RootStore
}

// Shared styles
const listItemStyles: SxProps<Theme> = {
  mb: 0.5,
  py: 0,
  px: 2,
}

// Create a function to get button styles based on depth, active state, and disabled state
const getButtonStyles = (
  depth: number,
  active: boolean,
  isDisabled = false,
) => {
  const paddingLeft = depth > 0 ? 32 + 8 * depth : 24

  return {
    // Base styles
    justifyContent: 'flex-start',
    pl: `${paddingLeft}px`,
    pr: 3,
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
    borderRadius: 1,
    fontWeight: active ? 'fontWeightBold' : undefined,

    // Theme-dependent styles
    backgroundColor: (theme: Theme) =>
      active
        ? theme.palette.mode === 'dark'
          ? 'neutral.800'
          : 'neutral.100'
        : 'transparent',

    color: (theme: Theme) => {
      if (isDisabled) return 'action.disabled'
      return active
        ? 'primary.main'
        : theme.palette.mode === 'dark'
        ? 'neutral.300'
        : 'neutral.700'
    },

    // Hover effect
    '&:hover': {
      backgroundColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
    },

    // Icon styles
    '& .MuiButton-startIcon': {
      color: (theme: Theme) =>
        active
          ? 'primary.main'
          : theme.palette.mode === 'dark'
          ? 'neutral.300'
          : 'neutral.600',
    },

    // End icon style (for branch items)
    '& .MuiButton-endIcon': {
      color: 'neutral.400',
    },
  }
}

// Branch component for items with children
const BranchItem: FC<BranchItemProps> = memo(
  ({
    active,
    children,
    depth,
    icon,
    info,
    open: openProp = false,
    title,
    id,
    disabled,
    root,
    ...other
  }) => {
    const [open, setOpen] = useState<boolean>(openProp)

    const handleToggle = useCallback((): void => {
      setOpen(prevOpen => !prevOpen)
    }, [])

    return (
      <ListItem
        disableGutters
        sx={{
          ...listItemStyles,
          display: 'block',
        }}
        {...other}
        data-onborda={id}
      >
        <Button
          disabled={root.disableNav || disabled}
          endIcon={
            !open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronDownIcon fontSize="small" />
            )
          }
          disableRipple
          onClick={handleToggle}
          startIcon={icon}
          sx={getButtonStyles(depth, active, disabled)}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open} sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </ListItem>
    )
  },
)

// Leaf component for items without children
const LeafItem: FC<LeafItemProps> = memo(
  ({
    active,
    chip,
    depth,
    icon,
    info,
    path,
    title,
    id,
    disabled,
    onDisabledLinkClick,
    root,
    ...other
  }) => {
    const handleClick = useCallback(() => {
      if (disabled && onDisabledLinkClick) {
        onDisabledLinkClick()
      }
    }, [disabled, onDisabledLinkClick])

    return (
      <ListItem
        disableGutters
        sx={{
          ...listItemStyles,
          display: 'flex',
        }}
        data-onborda={id}
        {...other}
      >
        {disabled ? (
          <Button
            disabled={root.disableNav}
            onClick={handleClick}
            startIcon={icon}
            endIcon={chip}
            disableRipple
            sx={getButtonStyles(depth, active, true)}
          >
            {title}
          </Button>
        ) : (
          <NextLink href={path} passHref>
            <Button
              disabled={root.disableNav}
              component="a"
              startIcon={icon}
              endIcon={chip}
              disableRipple
              sx={getButtonStyles(depth, active)}
            >
              <Box sx={{ flexGrow: 1 }}>{title}</Box>
              {info}
            </Button>
          </NextLink>
        )}
      </ListItem>
    )
  },
)

// Main component that decides whether to render a branch or leaf item
export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = memo(
  props => {
    const {
      active = false,
      children,
      chip,
      depth,
      icon,
      info,
      open = false,
      path,
      title,
      id,
      disabled,
      onDisabledLinkClick,
      ...other
    } = props

    const root = useRoot()

    // Branch
    if (children) {
      return (
        <BranchItem
          active={active}
          children={children}
          depth={depth}
          icon={icon}
          info={info}
          open={open}
          title={title}
          id={id}
          disabled={disabled}
          root={root}
          {...other}
        />
      )
    }

    // Leaf
    return (
      <LeafItem
        active={active}
        chip={chip}
        depth={depth}
        icon={icon}
        info={info}
        path={path}
        title={title}
        id={id}
        disabled={disabled}
        onDisabledLinkClick={onDisabledLinkClick}
        root={root}
        {...other}
      />
    )
  },
)
