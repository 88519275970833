'use client'
import React, { createContext, useCallback, useContext, useState } from 'react'

// Types
import { OnbordaContextType } from './types'

const OnbordaContext = createContext<OnbordaContextType | undefined>(undefined)

const useOnborda = () => {
  const context = useContext(OnbordaContext)
  if (context === undefined) {
    throw new Error('useOnborda must be used within an OnbordaProvider')
  }
  return context
}

const OnbordaProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentStep, setCurrentStepState] = useState(0)
  const [isOnbordaVisible, setOnbordaVisible] = useState(false)

  const setCurrentStep = useCallback((step: number, delay?: number) => {
    if (delay) {
      setTimeout(() => {
        setCurrentStepState(step)
        setOnbordaVisible(true)
      }, delay)
    } else {
      setCurrentStepState(step)
      setOnbordaVisible(true)
    }
  }, [])

  const closeOnborda = useCallback(() => {
    setOnbordaVisible(false)
    setCurrentStepState(null)
  }, [])

  const startOnborda = useCallback(() => {
    setCurrentStepState(0)
    setOnbordaVisible(true)
  }, [])

  return (
    <OnbordaContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        closeOnborda,
        startOnborda,
        isOnbordaVisible,
      }}
    >
      {children}
    </OnbordaContext.Provider>
  )
}

export { OnbordaProvider, useOnborda }
