import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Typography,
} from '@mui/material'
import axios from 'axios'
import api from 'utils/api'
import useToast from 'hooks/use-toast'
import { useAuth } from 'store/useAuth'

export const HelpFormDialog = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const user = useAuth(store => store.user)
  const [formData, setFormData] = useState({
    name: `${user.firstName} ${user.lastName}`,
    email: user.email ?? '',
    phoneNumber: user.phoneNumber ?? '',
    message: '',
  })

  const toast = useToast()

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!formData.message) {
      toast.notifyError('Message is required')
      return
    }
    setLoading(true)

    try {
      const response = await api.post('/agents/help-email', formData)
      toast.notifySuccess('Message sent successfully')
      onClose()
    } catch (error) {
      toast.notifyError('Failed to send message')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Need Help?</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Name"
          name="name"
          type="text"
          size="small"
          fullWidth
          variant="outlined"
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          label="E-mail"
          name="email"
          type="email"
          size="small"
          fullWidth
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          label="Phone Number"
          name="phoneNumber"
          type="tel"
          size="small"
          fullWidth
          variant="outlined"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          label="Message"
          name="message"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          helperText="Please provide details on how we can assist"
        />
        <DialogActions>
          <Button
            disabled={loading}
            onClick={onClose}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
