export const profilePaidSteps: any = [
  // Example steps
  {
    icon: <>👋</>,
    title: 'Welcome to Realty.com!',
    content: <>Welcome to Realty.com, let's get you onboarded!</>,
    selector: '[data-onborda="welcome-user-dashboard"]',
    side: 'bottom-left',
    showControls: true,
    pointerPadding: 10,
    pointerRadius: 5,
    nextRoute: '/profile',
  },

  {
    icon: <>🚀</>,
    title: 'Complete Your Profile Details',
    content: (
      <>
        Enter and update your personal and professional information to make your
        profile stand out.
      </>
    ),
    selector: '[data-onborda="profile"]',
    side: 'right',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 2,
    prevRoute: '/dashboard',
  },
  {
    icon: <>🪄</>,
    title: 'Preview Your Profile',
    content: (
      <>
        Enhance your profile visibility and attract more potential clients with
        a comprehensive profile.
      </>
    ),
    selector: '[data-onborda="profile-preview"]',
    side: 'center',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
  },
  {
    icon: <>💁‍♂️🪄</>,
    title: 'Update Your Profile',
    content: (
      <>
        Enter and update your personal and professional information to make your
        profile stand out!
      </>
    ),
    selector: '[data-onborda="profile-edit"]',
    side: 'right-top',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    prevRoute: '/profile',
    nextRoute: '/profile?tab=links',
  },
  {
    icon: <>🔗</>,
    title: 'Link Your Social Media Accounts',
    content: (
      <>
        Head over to the links tab to connect social media profiles to your
        Realty.com profile.
      </>
    ),
    selector: '[data-onborda="profile-tab-links"]',
    side: 'bottom',
    showControls: true,
    pointerPadding: 5,
    pointerRadius: 5,
    prevRoute: '/profile?tab=details',
  },
  {
    icon: <>🔗</>,
    title: 'Link Your Social Media Accounts',
    content: (
      <>
        Increase your online presence and engage with clients across multiple
        platforms.
      </>
    ),
    selector: '[data-onborda="profile-social"]',
    side: 'center',
    showControls: true,
    pointerPadding: 5,
    pointerRadius: 5,
    nextRoute: '/profile?tab=serviceAreas',
  },
  {
    icon: <>🔗</>,
    title: 'Define Your Service Areas',
    content: (
      <>Head over to the service area tab to link your areas of operation</>
    ),
    selector: '[data-onborda="profile-tab-serviceAreas"]',
    side: 'bottom',
    showControls: true,
    pointerPadding: 5,
    pointerRadius: 5,
    prevRoute: '/profile?tab=links',
  },
  {
    icon: <>📍</>,
    title: 'Define Your Service Areas',
    content: (
      <>
        Specify the areas you serve to ensure potential clients know where you
        operate.
      </>
    ),
    selector: '[data-onborda="profile-service-areas"]',
    side: 'bottom',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    prevRoute: '/profile?tab=links',
    nextRoute: '/campaigns',
  },
  {
    icon: <>🪄</>,
    title: 'Enroll Clients in Campaigns',
    content: (
      <>Add clients to automated homeowner campaigns to keep them informed!</>
    ),
    selector: '[data-onborda="campaigns"]',
    side: 'right',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    prevRoute: '/profile?tab=serviceAreas',
  },
  {
    icon: <>🪄</>,
    title: 'Enroll Clients in Campaigns',
    content: (
      <>
        Nurture relationships and stay top-of-mind with your clients through
        consistent communication.
      </>
    ),
    selector: '[data-onborda="campaigns-table"]',
    side: 'center',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    nextRoute: '/blog',
  },
  {
    icon: <>✍️</>,
    title: 'Leverage AI-Generated Blog Posts',
    content: (
      <>Utilize AI to generate and edit weekly blog posts for your audience.</>
    ),
    selector: '[data-onborda="blog"]',
    side: 'right',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    prevRoute: '/campaigns',
    nextRoute: '/integrations',
  },
  {
    icon: <>⚙️</>,
    title: 'Seamlessly Integrate Your CRM',
    content: (
      <>
        {' '}
        Connect your existing CRM to streamline client management and
        communication.
      </>
    ),
    selector: '[data-onborda="integrations"]',
    side: 'right',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    prevRoute: '/blog',
  },
  {
    icon: <>⚙️</>,
    title: 'Seamlessly Integrate Your CRM',
    content: (
      <>
        Having all your client information in one place will save you time and
        ensure that no leads fall through the cracks.
      </>
    ),
    selector: '[data-onborda="integrations-preview"]',
    side: 'center',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
  },
  {
    icon: <>🔍</>,
    title: 'Missing CRM Integration?',
    content: (
      <>If you're missing a CRM integration, click here to request one!</>
    ),
    selector: '[data-onborda="integrations-missing-crm"]',
    side: 'top',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    nextRoute: '/account?tab=notifications',
  },
  {
    icon: <>🔔</>,
    title: 'Customize Your Notifications',
    content: (
      <>
        Set up and personalize notifications to stay updated on important
        activities and leads
      </>
    ),
    selector: '[data-onborda="account"]',
    side: 'right',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    prevRoute: '/integrations',
  },
  {
    icon: <>🔔</>,
    title: 'Customize Your Notifications',
    content: (
      <>
        Never miss critical updates or opportunities by receiving timely
        notifications tailored to your preferences.
      </>
    ),
    selector: '[data-onborda="notifications-settings-inquiry"]',
    side: 'center',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    nextRoute: '/dashboard',
    prevRoute: '/account?tab=notifications',
  },
  {
    icon: <>📈</>,
    title: 'Access Live Coaching Sessions',
    content: (
      <>
        Participate in weekly live coaching sessions for expert advice and
        strategies.
      </>
    ),
    selector: '[data-onborda="coaching"]',
    side: 'right-top',
    showControls: true,
    pointerPadding: 0,
    pointerRadius: 5,
    prevRoute: '/dashboard',
  },
]
