import type { FC } from 'react'
import { Drawer, DrawerProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useAuth } from 'store/useAuth'
import React from 'react'

interface StyledDrawerWrapperProps extends DrawerProps {}

export const StyledDrawerWrapper: FC<StyledDrawerWrapperProps> = ({
  children,
  ...other
}) => {
  const user = useAuth(state => state.user)

  const StyledDrawer = React.useMemo(() => {
    return styled(Drawer)({
      flexShrink: 0,
      width: '100%',
      //  zIndex: theme => theme.zIndex.appBar + 500,
      maxWidth: '500px',
      '& .MuiDrawer-paper': {
        // height: user.impersonatedBy && 'calc(100% - 105px)',
        //top: user.impersonatedBy ? 42 : 0,

        width: '100%',
        // small screens (mobile)
        '@media (max-width: 600px)': {
          maxWidth: '500px',
        },
        // medium screens (tablet)
        '@media (min-width: 600px)': {
          maxWidth: '600px',
        },
      },
    })
  }, [])

  return <StyledDrawer {...other}>{children}</StyledDrawer>
}
