import type { FC } from 'react'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import { ProfileUpgrade } from 'components/profile/profile-upgrade'
import { ProfileUpgradeDialog } from 'components/profile/profile-upgrade-dialog'
import { useLocalStorage } from 'hooks/use-localstorage'
import { useTranslationLayer } from 'hooks/use-translation'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { scopes } from 'scopes'
import { useRoot } from 'store/useRoot'
import { getCZEmailDomain, getTenantName, isMainSite } from 'tenant'
import type { Member } from 'types/members'
import api from 'utils/api'
import { useFeatureFlags } from 'lib/features/hooks/useFeatureFlags'

import {
  AttachMoney as BillingIcon,
  Campaign as CampaignIcon,
  ChangeHistory as ConeZoneIcon,
  DoubleArrow as ActivityIcon,
  EditLocation as LocationIcon,
  EditNote as EditNoteIcon,
  Launch as LaunchPadIcon,
  NetworkPing as PingLogIcon,
  OpenInNew as LinkOffIcon,
  QueryStats as BillingStatsIcon,
  TaskAlt as TaskIcon,
  Timeline as ForecastingIcon,
  Upgrade as UpgradeIcon,
  Celebration as FrenzyIcon,
} from '@mui/icons-material'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import AssistantIcon from '@mui/icons-material/Assistant'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SourceIcon from '@mui/icons-material/Source'
import type { Theme } from '@mui/material'
import {
  Box,
  Button,
  Card,
  CardContent,
  Drawer,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { scope } from '@realty/portal'

import { ChartPie as DashboardIcon } from '../../icons/chart-pie'
import { Cog as CogIcon } from '../../icons/cog'
import { Filter as LeadIcon } from '../../icons/filter'
import { OfficeBuilding as OfficeBuildingIcon } from '../../icons/office-building'
import { User as UserIcon } from '../../icons/user'
import { UserCircle as UserCircleIcon } from '../../icons/user-circle'
import { Users as UsersIcon } from '../../icons/users'
import { useAuth } from '../../store/useAuth'
import { SETTINGS } from '../../types/scopes/global'
import { GROUPS, MEMBER, ROLES } from '../../types/scopes/management'
import { Logo } from '../logo'
import { Scrollbar } from '../scrollbar'
import { DashboardSidebarSection } from './dashboard-sidebar-section'
import { OrganizationPopover } from './organization-popover'

const { config: scopeConfig } = scope

interface DashboardSidebarProps {
  onClose: () => void
  open: boolean
}

interface Children {
  title: string
  children?: Children[]
  chip?: ReactNode
  icon?: ReactNode
  path?: string
  scopes?: string[]
}

interface Section {
  title: string
  children: Children[]
}

function filter(array, fn) {
  return array.reduce((r, o) => {
    var children = filter(o.children || [], fn)
    if (fn(o) || children.length)
      r.push(Object.assign({}, o, children.length && { children }))
    return r
  }, [])
}

const doCircleSSO = async () => {
  const newWindow = window.open('', '_blank')

  try {
    const result = await api.get('/gencircle')

    newWindow.location.href = `https://realty.dev/api/circle/login?token=${result.data.jwt}`
  } catch (error) {
    newWindow.close()
    console.error('Error fetching the token:', error)
  }
}
const buildIntegrationPath = (user?: Member) => {
  let path = '/integrations'
  const params = new URLSearchParams()
  if (user?.id) {
    params.append('member_id', user.id.toString())
  }

  if (user?.primaryAssignment?.brandingGroup?.id) {
    params.append(
      'group_id',
      user.primaryAssignment.brandingGroup.id.toString(),
    )
  }

  return `${path}?${params.toString()}`
}
const getSections = (
  t: TFunction,
  userScopes: string[],
  isNavDisabled: boolean,
  isPremiumUser: boolean,
  opts?: { warmTransfer?: boolean; hideGroups?: boolean; user?: Member },
): Section[] =>
  filter(
    [
      {
        label: 'LaunchPad',

        children: [
          {
            title: t('Launchpad'),
            path: '/launchpad',
            icon: (
              <LaunchPadIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            scopes: [scopeConfig.General.Leads.VIEW],
          },
        ],
      },
      {
        title: t('General'),
        children: [
          {
            title: t('Dashboard'),
            path: '/dashboard',
            icon: (
              <DashboardIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            scopes: [scopeConfig.General.Leads.VIEW],
            // hideOnScopes: [
            //   scopeConfig.General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS,
            // ],
          },

          {
            title: t('Leads'),
            path: '/leads',
            id: 'leads',
            scopes: [scopeConfig.General.Leads.VIEW],

            icon: (
              <LeadIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },

          {
            title: t('Frenzy'),
            path: '/frenzy',
            id: 'frenzy',
            scopes: [scopeConfig.General.Leads.VIEW],
            hideOnScopes: [scopeConfig.Management.Roles.IS_SUPER_ADMIN],
            icon: (
              <FrenzyIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },

          {
            title: t('Frenzy'),
            path: '/frenzy_admin',
            id: 'frenzy_admin',
            scopes: [scopeConfig.Management.Roles.IS_SUPER_ADMIN],
            icon: (
              <FrenzyIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },

          {
            title: t('Activities'),
            path: '/activities',
            id: 'activities',
            // hideOnScopes: [
            //   scopeConfig.General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS,
            // ],
            scopes: [scopeConfig.General.Leads.VIEW],
            icon: (
              <ActivityIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            children: [
              {
                scopes: [scopeConfig.General.Leads.VIEW],
                title: t('Inquiries'),
                path: '/activities/inquiries',
                // hideOnScopes: [
                //   scopeConfig.General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS,
                // ],
              },
              {
                scopes: [scopeConfig.General.Leads.VIEW],
                title: t('Requests'),
                path: '/activities/requests',
                // hideOnScopes: [
                //   scopeConfig.General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS,
                // ],
              },
              {
                scopes: [scopeConfig.General.Leads.VIEW],
                title: t('Saved Searches'),
                path: '/activities/searches',
                // hideOnScopes: [
                //   scopeConfig.General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS,
                // ],
              },
              {
                scopes: [scopeConfig.General.Leads.VIEW],
                title: t('Properties'),
                path: '/activities/properties',
                // hideOnScopes: [
                //   scopeConfig.General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS,
                // ],
              },
              {
                scopes: [
                  scopeConfig.Management.Roles.IS_SUPER_ADMIN,
                  scopeConfig.General.Leads.STAGE_CHANGE,
                ],
                title: t('Stage Changes'),
                path: '/activities/stages',
                // mainSiteOnly: true,
              },
            ],
          },

          {
            title: t('Campaigns'),
            path: '/campaigns',
            id: 'campaigns',
            disabled: !isPremiumUser,
            icon: (
              <CampaignIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            scopes: [scopeConfig.Management.Campaigns.VIEW],
          },

          {
            title: t('Account'),
            path: '/account?tab=general',
            id: 'account',
            scopes: [scopeConfig.Management.Members.ACCOUNT_MANAGE],
            icon: (
              <UserCircleIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },
        ],
      },
      {
        title: t('Management'),
        children: [
          {
            title: t('Members'),
            path: '/members',
            id: 'members',
            scopes: [MEMBER.VIEW],
            icon: (
              <UserIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            // children: [
            //   {
            //     scopes: [MEMBER.ALL],
            //     title: t('List'),
            //     path: '/members',
            //   },
            //   {
            //     scopes: [MEMBER.CREATE],
            //     title: t('Create'),
            //     path: '/members/create',
            //   },
            // ],
          },

          {
            title: t('Transfer Agents'),
            path: '/transfer-agents',
            mainSiteOnly: true,
            id: 'transfer-agents',
            scopes: [scopeConfig.Management.Roles.IS_BRAND_ADMIN],
            showItem: opts?.warmTransfer,
            icon: (
              <UsersIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            // children: [
            //   {
            //     scopes: [GROUPS.ALL],
            //     title: t('List'),
            //     path: '/groups',
            //   },
            //   {
            //     scopes: [GROUPS.CREATE],
            //     title: t('Create'),
            //     path: '/groups/create',
            //   },
            // ],
          },
          {
            title: t('Cone Zone'),
            id: 'cone-zone',
            path: '/cone-zone',
            scopes: [
              scope.config.Management.Onboard.CONE_ZONE,
              scope.config.Management.Onboard.CONE_ZONE_SUPER,
            ],
            mainSiteOnly: true,
            icon: (
              <ConeZoneIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            // children: [
            //   {
            //     scopes: [ONBOARD.CREATE_WIZARD],
            //     title: t('Wizard'),
            //     path: '/onboard',
            //   },
            // ],
          },

          {
            title: t('Onboard'),
            path: '/onboard',
            id: 'onboard',
            scopes: [
              scope.config.Management.Onboard.TENANT,
              scope.config.Management.Onboard.BRAND,
            ],

            icon: (
              <AssistantIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            // children:[
            //   {
            //     scopes: [ONBOARD.CREATE_WIZARD],
            //     title: t('Wizard'),
            //     path: '/onboard',
            //   },
            // ],
          },
          {
            title: t('Brands'),
            path: '/brands',
            id: 'brands',
            icon: (
              <OfficeBuildingIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            scopes: [ROLES.IS_SUPER_ADMIN],
            children: [
              {
                scopes: [ROLES.IS_SUPER_ADMIN],
                title: t('List'),
                path: '/brands',
              },
              {
                scopes: [ROLES.IS_SUPER_ADMIN],
                title: t('Create'),
                path: '/brands/create',
              },
            ],
          },
          {
            title: t('Groups'),
            showItem: opts.hideGroups,

            path: '/groups',
            id: 'groups',
            scopes: [GROUPS.VIEW, !scopeConfig.Management.Profile.VIEW_MODIFY],
            icon: (
              <UsersIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            // children: [
            //   {
            //     scopes: [GROUPS.ALL],
            //     title: t('List'),
            //     path: '/groups',
            //   },
            //   {
            //     scopes: [GROUPS.CREATE],
            //     title: t('Create'),
            //     path: '/groups/create',
            //   },
            // ],
          },

          {
            title: t('Locations'),
            path: '/location',
            id: 'locations',
            icon: (
              <LocationIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            scopes: [scopeConfig.Management.Roles.IS_SUPER_ADMIN],
            mainSiteOnly: true,
            children: [
              {
                scopes: [scopeConfig.Management.Roles.IS_SUPER_ADMIN],
                title: t('City'),
                path: '/location/cities',
                mainSiteOnly: true,
              },
              {
                scopes: [scopeConfig.Management.Roles.IS_SUPER_ADMIN],
                title: t('Zip'),
                mainSiteOnly: true,
                path: '/location/zips',
              },
            ],
          },

          {
            title: t('Ping Logs'),
            path: '/ping-logs',
            id: 'ping-logs',
            icon: (
              <PingLogIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            scopes: [scopeConfig.Management.Groups.PING_LOGS],
            mainSiteOnly: true,
          },
          {
            title: t('MLS Feeds'),
            path: '/mls',
            id: 'mls',
            icon: (
              <SourceIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            scopes: [scopeConfig.Management.Roles.IS_SUPER_ADMIN],
            mainSiteOnly: true,
            // children: [
            //   {
            //     scopes: [SETTINGS.ACCESS],
            //     title: t('Feeds'),
            //     path: '/mls',
            //   },
            // ],
          },

          //   {
          //     title: t('Announcements'),
          //     path: '/announcements',
          //     icon: (
          //       <AnnouncementIcon
          //         fontSize="small"
          //         sx={{ color: isNavDisabled && 'action.disabled' }}
          //       />
          //     ),
          //     scopes: [ROLES.IS_SUPER_ADMIN],
          //   },

          //   {
          //     title: t('Unclaimed Agents'),
          //     path: '/unclaimed_agents',
          //     icon: (
          //       <AgentUnclaimedIcon
          //         fontSize="small"
          //         sx={{ color: isNavDisabled && 'action.disabled' }}
          //       />
          //     ),
          //     scopes: [
          //       scopeConfig.Management.Members.VIEW_MODIFY_UNCLAIMED_AGENT,
          //     ],
          //     mainSiteOnly: true,
          //   },

          {
            title: t('Forecasting'),
            path: '/forecasting',
            id: 'forecasting',
            icon: (
              <ForecastingIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
            scopes: [
              scopeConfig.Management.Onboard.CONE_ZONE,
              scopeConfig.Management.Onboard.CONE_ZONE_SUPER,
            ],
            mainSiteOnly: true,
          },
          {
            title: t('Verified Profile'),
            path: '/profile',
            id: 'profile',
            scopes: [scopeConfig.Management.Profile.VIEW_MODIFY],
            icon: (
              <ManageAccountsIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },
          {
            title: t('AI Blogging'),
            path: '/blog',
            id: 'blog',
            scopes: [scopeConfig.Management.Profile.BLOG],
            hideOnScopes: [
              scopeConfig.General.Leads.RESTRICTED_LEAD_SOURCE_ACCESS,
            ],
            icon: (
              <EditNoteIcon
                fontSize="small"
                sx={{
                  color: (isNavDisabled || !isPremiumUser) && 'action.disabled',
                }}
              />
            ),
            disabled: !isPremiumUser,
          },
          {
            title: t('Task Management'),
            path: '/todos',
            id: 'todos',
            scopes: [scopeConfig.Management.Tasks.VIEW],
            icon: (
              <TaskIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },
          {
            scopes: [scopeConfig.Management.Profile.INTEGRATION],
            title: t('Integrations'),
            path: buildIntegrationPath(opts?.user),
            id: 'integrations',
            icon: (
              <AppRegistrationIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),

            children: [
              {
                title: t('Installed'),
                path: '/integrations/installed',
                disabled: !isPremiumUser,
                scopes: [scopeConfig.Management.Profile.INTEGRATION],
              },
              {
                title: t('All Integrations'),
                path: buildIntegrationPath(opts?.user),
                disabled: !isPremiumUser,
                scopes: [scopeConfig.Management.Profile.INTEGRATION],
              },
            ],
          },
        ],
      },

      {
        title: t('Global'),
        // path: '/settings',
        children: [
          {
            scopes: [SETTINGS.ACCESS],
            title: t('Settings'),
            path: '/settings?tab=general',
            id: 'settings',
            icon: (
              <CogIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },
          {
            scopes: [SETTINGS.ACCESS],
            title: t('Integrations'),
            path: '/integrations',
            id: 'integrations',
            icon: (
              <AppRegistrationIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },

          {
            scopes: [scopes.Management.Roles.LIST],
            title: t('Roles'),
            path: '/roles',
            id: 'roles',
            icon: (
              <FingerprintIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },
        ],
      },

      {
        title: t('Tools'),
        // path: '/settings',
        children: [
          {
            scopes: [scopeConfig.Management.Brands.BILLING],
            title: t('Billing'),
            path: '/billing',
            id: 'billing',
            icon: (
              <BillingIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },
          {
            scopes: [scopeConfig.Management.Brands.BILLING],
            title: t('Billing Stats'),
            path: '/billing/dashboard',
            id: 'billing-stats',
            icon: (
              <BillingStatsIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },

          {
            scopes: [
              scopeConfig.Management.Groups.GLOBAL_TRACKDRIVE,
              scopeConfig.Management.Groups.VIEW_TRANSFERS_OVERVIEW,
            ],
            title: t('Transfers Overview'),
            path: '/transfers-bg',
            id: 'transfers-overview',
            icon: (
              <FingerprintIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },

          {
            scopes: [scopeConfig.Management.Groups.GLOBAL_TRACKDRIVE],
            title: t('Transfers'),
            path: '/transfers',
            id: 'transfers',
            icon: (
              <FingerprintIcon
                fontSize="small"
                sx={{ color: isNavDisabled && 'action.disabled' }}
              />
            ),
          },
        ],
      },
    ],
    ({ scopes, mainSiteOnly, showItem, disabled, hideOnScopes }) => {
      if (disabled) {
        return true
      }

      if (hideOnScopes) {
        return hideOnScopes?.some(scope => {
          if (userScopes.includes(scope)) {
            return false
          }
          return true
        })
      }

      return scopes?.some(scope => {
        if (mainSiteOnly) {
          if (isMainSite()) {
            if (showItem === false) {
              return false
            }
            return userScopes.includes(scope)
          } else {
            return false
          }
        }
        if (showItem === false) {
          return false
        }
        return userScopes.includes(scope)
      })
    },
  )

export const DashboardSidebar: FC<DashboardSidebarProps> = props => {
  const { onClose, open } = props
  const router = useRouter()
  const { t } = useTranslation()
  const { config } = useTranslationLayer()
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  })
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false)
  const root = useRoot()
  const [dontShowAgain] = useLocalStorage<boolean>(
    'dontShowProfileUpgradeAgain',
    false,
  )

  const user = useAuth(item => item.user)
  const flags = useFeatureFlags(user?.subscriptions, user?.brand)

  const showWarmTransfer = Boolean(
    user?.primaryAssignment?.brandingGroup?.buyerGroupId ||
      user?.primaryAssignment?.brandingGroup?.isa,
  )
  console.log('isFreeUser', flags?.isFreeUser())
  const isPremiumUser = !isMainSite() || (isMainSite() && !flags?.isFreeUser())
  console.log('isPremiumUser', isPremiumUser)
  const sections = useMemo(
    () =>
      getSections(t, user.scopes, root.disableNav, isPremiumUser, {
        warmTransfer: showWarmTransfer,
        hideGroups: !user?.scopes?.includes(
          scopeConfig.Management.Roles.IS_BRAND_ADMIN,
        ),
        user,
      }).filter(item => {
        if (item.label === 'LaunchPad') {
          if (getTenantName() === 'homescout') {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }),
    [t, user, root.disableNav, isPremiumUser, showWarmTransfer],
  )

  const organizationsRef = useRef<HTMLButtonElement | null>(null)
  const [openOrganizationsPopover, setOpenOrganizationsPopover] =
    useState<boolean>(false)

  const handlePathChange = () => {
    if (!router.isReady) {
      return
    }

    if (open) {
      onClose?.()
    }
  }

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, router.asPath],
  )
  const handleDisabledLinkClick = (): void => {
    console.log('Disabled link clicked')
    setOpenUpgradeDialog(true)
  }
  const handleOpenOrganizationsPopover = (): void => {
    setOpenOrganizationsPopover(true)
  }

  const handleCloseOrganizationsPopover = (): void => {
    setOpenOrganizationsPopover(false)
  }

  //   const handleOpenUpgradeDialog = (): void => {
  //     setOpenUpgradeDialog(true)
  //   }

  //   const handleCloseUpgradeDialog = (): void => {
  //     setOpenUpgradeDialog(false)
  //   }

  // //   useEffect(() => {
  // //     if (user && !dontShowAgain && !isPremiumUser && isMainSite()) {
  // //       setOpenUpgradeDialog(true)
  // //     }
  // //   }, [user, dontShowAgain])

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box sx={{ pt: 3, textAlign: 'center' }}>
            <NextLink href="/" passHref>
              <a>
                <Logo
                  sx={{
                    width: 100,
                  }}
                />
              </a>
            </NextLink>
          </Box>

          {(getCZEmailDomain() === 'Realty.com' ||
            getCZEmailDomain() === 'Realty.ca' ||
            getCZEmailDomain() === 'Listing.com') && (
            <Box sx={{ p: 2 }} data-onborda="coaching">
              <Button
                // href="https://community.realty.com"
                // target="_blank"
                // rel="noreferrer"
                size="small"
                onClick={doCircleSSO}
                // component="a"
                endIcon={<LinkOffIcon />}
                fullWidth
                sx={{ mt: 2 }}
                variant="outlined"
              >
                {t('Coaching')}
              </Button>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }}>
            {sections.map((section, index) => (
              <DashboardSidebarSection
                key={index}
                path={router.asPath}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2,
                  },
                }}
                onDisabledLinkClick={handleDisabledLinkClick}
                {...section}
              />
            ))}
          </Box>
          <ProfileUpgrade />
        </Box>
      </Scrollbar>
      <OrganizationPopover
        anchorEl={organizationsRef.current}
        onClose={handleCloseOrganizationsPopover}
        open={openOrganizationsPopover}
      />
    </>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: theme =>
              theme.palette.mode === 'dark' ? 'default' : '#F9FAFC',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: theme => (theme.palette.mode === 'dark' ? 1 : 1),
            color: theme => 'red',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: theme =>
            theme.palette.mode === 'dark' ? 'default' : '#F9FAFC',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: theme => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

//build
