'use client'
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useHotkeys } from 'react-hotkeys-hook';

import { Box } from '@mui/material';

import executeActions from './onborda-actions';
import {
    CardArrow,
    DefaultCard,
} from './onborda-card';
import { useOnborda } from './onborda-context';
import {
    getCardStyle,
    StyledBox,
    StyledCard,
    StyledOverlay,
    StyledPointer,
} from './onborda-styles';
import { OnbordaProps } from './types';

const Onborda: React.FC<OnbordaProps> = ({
  children,
  steps,
  shadowRgb = '0, 0, 0',
  shadowOpacity = '0.2',
  cardComponent: CardComponent,
}) => {
  const { currentStep, setCurrentStep, isOnbordaVisible, closeOnborda } =
    useOnborda()
  const [pointerPosition, setPointerPosition] = useState<{
    x: number
    y: number
    width: number
    height: number
  } | null>(null)
  const [isElementRendered, setIsElementRendered] = useState(false)
  useHotkeys('escape', () => closeOnborda())

  const currentElementRef = useRef<Element | null>(null)
  const router = useRouter()
  const pathname = router.pathname

  const getElementPosition = useCallback((element: Element) => {
    const { top, left, width, height } = element.getBoundingClientRect()
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft
    return {
      x: left + scrollLeft,
      y: top + scrollTop,
      width,
      height,
    }
  }, [])

  const scrollToElement = useCallback((element: Element) => {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [])
  const updatePointerPosition = useCallback(
    debounce(() => {
      if (currentElementRef.current) {
        const newPosition = getElementPosition(currentElementRef.current)
        setPointerPosition(newPosition)
      }
    }, 200),
    [getElementPosition],
  )

  const checkAndUpdateElement = useCallback(
    (selector: string, retries = 10, interval = 200) => {
      let attempts = 0
      const check = () => {
        const targetElement = document.querySelector(selector) as Element
        if (targetElement) {
          const newPosition = getElementPosition(targetElement)
          if (newPosition.width > 0 && newPosition.height > 0) {
            currentElementRef.current = targetElement
            setIsElementRendered(true)
            setPointerPosition(newPosition)
            scrollToElement(targetElement)
            return
          }
        }

        if (attempts < retries) {
          attempts++
          setTimeout(check, interval)
        } else {
          setIsElementRendered(false)
          setPointerPosition(null)
        }
      }
      check()
    },
    [getElementPosition, scrollToElement],
  )

  const executeStep = async (step: any) => {
    // Update the element and execute its actions
    checkAndUpdateElement(step.selector)
    await new Promise(resolve => setTimeout(resolve, 1000)) // Wait for the element to render

    if (step.actions && step.actions.length > 0) {
      await executeActions(step.actions, executeStep)
    }
  }

  const handleStepChange = useCallback(
    (newStep: number) => {
      setIsElementRendered(false)
      setPointerPosition(null)
      setCurrentStep(newStep)
    },
    [setCurrentStep],
  )

  useEffect(() => {
    if (isOnbordaVisible && steps.length > 0) {
      const step = steps[currentStep]
      if (step) {
        console.log('step', step)
        checkAndUpdateElement(step.selector)
      }
    }
  }, [
    isOnbordaVisible,
    steps,
    currentStep,
    checkAndUpdateElement,
    pathname,
    router,
  ])

  useEffect(() => {
    if (isOnbordaVisible && isElementRendered) {
      const updateInterval = setInterval(updatePointerPosition, 100)
      setTimeout(() => clearInterval(updateInterval), 2000) // Update for 2 seconds

      // Execute actions for the current step
      const step = steps[currentStep]
      if (step.actions && step.actions.length > 0) {
        executeActions(step.actions, executeStep)
      }

      return () => clearInterval(updateInterval)
    }
  }, [isOnbordaVisible, isElementRendered, updatePointerPosition])

  useEffect(() => {
    if (isOnbordaVisible) {
      window.addEventListener('resize', updatePointerPosition)
      return () => window.removeEventListener('resize', updatePointerPosition)
    }
  }, [updatePointerPosition, isOnbordaVisible])

  const prevStep = useCallback(async () => {
    if (currentStep > 0) {
      const prevStepIndex = currentStep - 1
      const route = steps[currentStep].prevRoute

      if (route && route !== pathname) {
        handleStepChange(prevStepIndex)
        await router.push(route)
      } else {
        handleStepChange(prevStepIndex)
      }
    }
  }, [currentStep, steps, pathname, router, handleStepChange])

  const nextStep = useCallback(async () => {
    if (currentStep < steps.length - 1) {
      const nextStepIndex = currentStep + 1
      const route = steps[currentStep].nextRoute

      if (route && route !== pathname) {
        handleStepChange(nextStepIndex)
        await router.push(route)
      } else {
        handleStepChange(nextStepIndex)
      }
    }
  }, [currentStep, steps, pathname, router, handleStepChange])

  const pointerPadding = steps[currentStep]?.pointerPadding ?? 30
  const pointerPadOffset = pointerPadding / 2
  const pointerRadius = steps[currentStep]?.pointerRadius ?? 28

  const CardToRender = CardComponent || DefaultCard

  return (
    <StyledBox data-name="onborda-wrapper" data-onborda="dev">
      <Box
        data-name="onborda-site"
        sx={{ position: 'relative', display: 'block', width: '100%' }}
      >
        {children}
      </Box>
      {isOnbordaVisible && (
        <StyledOverlay
          data-name="onborda-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          style={{
            backgroundColor: !isElementRendered
              ? `rgba(${shadowRgb}, ${shadowOpacity})`
              : 'transparent',
          }}
        >
          {pointerPosition && (
            <StyledPointer
              data-name="onborda-pointer"
              style={{
                boxShadow: `0 0 200vw 200vh rgba(${shadowRgb}, ${shadowOpacity})`,
                borderRadius: `${pointerRadius}px`,
              }}
              initial={
                pointerPosition
                  ? {
                      x: pointerPosition.x - pointerPadOffset,
                      y: pointerPosition.y - pointerPadOffset,
                      width: pointerPosition.width + pointerPadding,
                      height: pointerPosition.height + pointerPadding,
                    }
                  : {}
              }
              animate={
                pointerPosition
                  ? {
                      x: pointerPosition.x - pointerPadOffset,
                      y: pointerPosition.y - pointerPadOffset,
                      width: pointerPosition.width + pointerPadding,
                      height: pointerPosition.height + pointerPadding,
                    }
                  : {}
              }
              transition={{ ease: 'anticipate', duration: 0.3 }}
            >
              {isElementRendered && (
                <StyledCard
                  data-name="onborda-card"
                  style={getCardStyle(steps[currentStep]?.side as any)}
                >
                  <CardToRender
                    step={steps[currentStep]}
                    currentStep={currentStep}
                    totalSteps={steps.length}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    arrow={<CardArrow steps={steps} />}
                    steps={steps}
                  />
                </StyledCard>
              )}
            </StyledPointer>
          )}
        </StyledOverlay>
      )}
    </StyledBox>
  )
}

export default Onborda
