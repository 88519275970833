import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import useMemberNotificationAlert from 'components/common/notification/member-notification-alert'
// import useSound from 'use-sound'
import useNotificationAlert from 'components/common/notification/notification-alert'
import { useKBar } from 'kbar'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import Confetti from 'react-confetti'
import { useRoot } from 'store/useRoot'
import { useSocket } from 'store/useSocket'
import { getMemberProfileImageUrl } from 'tenant'
import api from 'utils/api'
import shallow from 'zustand/shallow'
import { useOnborda } from 'components/onborda'

import { Fingerprint } from '@mui/icons-material'
import type { AppBarProps } from '@mui/material'
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Chip,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { Bell as BellIcon } from '../../icons/bell'
import { Dark as DarkIcon } from '../../icons/dark'
import { Light as LightIcon } from '../../icons/light'
import { Menu as MenuIcon } from '../../icons/menu'
import { QuestionMarkOutlined as HelpIcon } from '../../icons/question-mark-outlined'
import { Search as SearchIcon } from '../../icons/search'
import { useAuth } from '../../store/useAuth'
import { useSettings } from '../../store/useSettings'
import { getInitials } from '../../utils/get-initials'
import { AccountPopover } from './account-popover'
import { NotificationDrawer } from './notification-drawer'
import sound from './sound2.mp3'
import { TenantSwitcher } from './tenant-switcher'
import { HelpFormDialog } from './help-dialog'
import { ImpersonateBanner } from './impersonate-banner'
interface DashboardNavbarProps extends AppBarProps {
  onOpenSidebar?: () => void
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none',
      }),
}))

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const drawGlitterDick = ctx => {
  const shaftColor = getRandomColor()
  const tipColor = getRandomColor()
  const ballsColor = getRandomColor()

  // Draw the shaft
  ctx.beginPath()
  ctx.moveTo(2, 5)
  ctx.lineTo(8, 5)
  ctx.lineTo(8, 25)
  ctx.lineTo(2, 25)
  ctx.closePath()
  ctx.fillStyle = shaftColor
  ctx.fill()

  // Draw the testicles without a stroke
  ctx.beginPath()
  ctx.arc(2, 30, 5, 0, Math.PI * 2, true) // Left
  ctx.arc(8, 30, 5, 0, Math.PI * 2, true) // Right
  ctx.fillStyle = ballsColor
  ctx.fill()

  // Draw the tip
  ctx.beginPath()
  ctx.arc(5, 2, 2.5, 0, Math.PI * 2, true)
  ctx.fillStyle = tipColor
  ctx.fill()
}

const drawChickenNugget = ctx => {
  ctx.beginPath()
  ctx.moveTo(5, 15)
  ctx.bezierCurveTo(5, 5, 25, 5, 25, 15)
  ctx.bezierCurveTo(25, 25, 15, 25, 10, 20)
  ctx.bezierCurveTo(5, 15, 5, 25, 5, 15)
  ctx.closePath()
  ctx.fillStyle = '#DAA520' // Golden color
  ctx.fill()
}
const drawPumpkin = ctx => {
  const x = 10 // Static x-coordinate
  const y = 10 // Static y-coordinate
  const size = 20 // Static size
  ctx.shadowColor = 'gray'
  ctx.shadowBlur = 5
  ctx.shadowOffsetX = 1
  ctx.shadowOffsetY = 1
  // Pumpkin body
  ctx.fillStyle = 'orange'
  ctx.beginPath()
  ctx.arc(x, y, size, 0, Math.PI * 2)
  ctx.fill()

  // Stem
  ctx.fillStyle = 'brown'
  ctx.fillRect(x - size / 8, y - size, size / 4, size / 4)

  // Left eye
  ctx.fillStyle = 'black'
  ctx.beginPath()
  ctx.arc(x - size / 4, y - size / 4, size / 8, 0, Math.PI * 2)
  ctx.fill()

  // Right eye
  ctx.beginPath()
  ctx.arc(x + size / 4, y - size / 4, size / 8, 0, Math.PI * 2)
  ctx.fill()

  // Mouth
  ctx.beginPath()
  ctx.moveTo(x - size / 4, y + size / 4)
  ctx.quadraticCurveTo(x, y + size / 3, x + size / 4, y + size / 4)
  ctx.quadraticCurveTo(x, y + size / 2, x - size / 4, y + size / 4)
  ctx.closePath()
  ctx.fill()
}

const drawGhost = ctx => {
  const x = 20 // Static x-coordinate
  const y = 20 // Static y-coordinate
  const size = 20 // Static size

  // Add shadow
  ctx.shadowColor = 'gray'
  ctx.shadowBlur = 5
  ctx.shadowOffsetX = 1
  ctx.shadowOffsetY = 1

  // Ghost body
  ctx.fillStyle = 'white'
  ctx.beginPath()
  // Draw the rounded head
  ctx.arc(x, y, size, Math.PI, Math.PI * 2, false)
  // Draw the flowing body
  ctx.lineTo(x + size, y)
  ctx.lineTo(x + size, y + size)
  ctx.lineTo(x + size * 0.8, y + size * 0.8)
  ctx.lineTo(x + size * 0.6, y + size)
  ctx.lineTo(x + size * 0.4, y + size * 0.8)
  ctx.lineTo(x + size * 0.2, y + size)
  ctx.lineTo(x - size * 0.2, y + size * 0.8)
  ctx.lineTo(x - size * 0.4, y + size)
  ctx.lineTo(x - size * 0.6, y + size * 0.8)
  ctx.lineTo(x - size * 0.8, y + size)
  ctx.lineTo(x - size, y + size)
  ctx.lineTo(x - size, y)
  ctx.closePath()
  ctx.fill()

  // Left eye
  ctx.fillStyle = 'black'
  ctx.beginPath()
  ctx.arc(x - size / 4, y - size / 4, size / 8, 0, Math.PI * 2)
  ctx.fill()

  // Right eye
  ctx.beginPath()
  ctx.arc(x + size / 4, y - size / 4, size / 8, 0, Math.PI * 2)
  ctx.fill()
}

// Predefined drawing functions
const drawingFunctions = {
  glitterDick: drawGlitterDick,
  chickenNugget: drawChickenNugget,
  pumpkin: drawPumpkin,
  ghost: drawGhost,
}

const ContentSearchButton = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const root = useRoot()

  const handleOpenSearchDialog = (): void => {
    setOpenDialog(true)
  }

  const handleCloseSearchDialog = (): void => {
    setOpenDialog(false)
  }
  const { query } = useKBar()

  return (
    <>
      <Tooltip title="Search">
        <IconButton
          disabled={root.disableNav}
          onClick={query.toggle}
          sx={{ ml: 1 }}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  )
}

const ContactsButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [openPopover, setOpenPopover] = useState<boolean>(false)

  const handleOpenPopover = (): void => {
    setOpenPopover(true)
  }

  const handleClosePopover = (): void => {
    setOpenPopover(false)
  }

  const { settings, saveSettings } = useSettings()
  const value = useSettings()

  const handleSave = () => {
    saveSettings({
      ...settings,
      theme: value.theme === 'dark' ? 'light' : 'dark',
    })
  }

  return (
    <>
      <Tooltip title={value.theme === 'dark' ? 'Light Mode' : 'Dark Mode'}>
        <IconButton onClick={handleSave} sx={{ ml: 1 }} ref={anchorRef}>
          {value.theme === 'dark' ? <DarkIcon /> : <LightIcon />}
        </IconButton>
      </Tooltip>
    </>
  )
}

const NotificationsButton: FC<{
  notifications: []
  setNotifications: any
}> = ({ notifications, setNotifications, resetNotifications }) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [unread, setUnread] = useState<number>(0)

  const rootRef = useRef<HTMLDivElement>()

  const root = useRoot()

  const [drawer, setDrawer] = useState<{
    isOpen: boolean
  }>({
    isOpen: false,
  })

  const handleOpenDrawer = (): void => {
    setDrawer({
      isOpen: true,
    })
  }

  const handleCloseDrawer = () => {
    setDrawer({
      isOpen: false,
    })
  }

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          disabled={root.disableNav}
          ref={anchorRef}
          sx={{ ml: 1 }}
          onClick={drawer.isOpen ? handleCloseDrawer : handleOpenDrawer}
        >
          <Badge color="error" badgeContent={unread}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>

      <NotificationDrawer
        notifications={notifications}
        setNotifications={setNotifications}
        resetNotifications={resetNotifications}
        onClose={handleCloseDrawer}
        open={drawer.isOpen}
        containerRef={rootRef}
      />
    </>
  )
}

const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const user = useAuth(state => state.user)

  const handleOpenPopover = (): void => {
    setOpenPopover(true)
  }

  const handleClosePopover = (): void => {
    setOpenPopover(false)
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2,
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40,
          }}
          src={
            user.profileImage
              ? getMemberProfileImageUrl({
                  memberId: user.id,
                  width: 100,
                  profileImage: user.profileImage,
                })
              : ''
          }
        >
          {getInitials(`${user.firstName} ${user.lastName}`)}
        </Avatar>
      </Box>
      {
        <AccountPopover
          anchorEl={anchorRef.current}
          onClose={handleClosePopover}
          open={openPopover}
        />
      }
    </>
  )
}

export const DashboardNavbar: FC<DashboardNavbarProps> = props => {
  const { onOpenSidebar, ...other } = props
  const audioPlayer = useRef(null)
  const router = useRouter()

  const [socketEventAdded, setSocketEventAdded] = useState(false)

  const [notifications, setNotifications] = useState([])
  const user = useAuth(state => state.user)
  const setAccessToken = useAuth(state => state.setAccessToken)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const [resetNotifications, setResetNotifications] = useState(false)
  const [loading, setLoading] = useState(false)

  const lct = user?.notificationPreferences?.lct
  const { notify } = useNotificationAlert()
  const [triggerConfetti, setTriggerConfetti] = useState(false)
  const [confettiStyle, setConfettiStyle] = useState(null)

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ])

  useEffect(() => {
    const updateSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize)
    updateSize() // Optional: To set initial size
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const { notify: notifyMember } = useMemberNotificationAlert()
  const { socket, initSocket, socketInitialised } = useSocket(
    state => ({
      socket: state.socket,
      initSocket: state.initSocket,
      socketInitialised: state.initialised,
    }),
    shallow,
  )

  function playAudio() {
    const audio = audioPlayer?.current
    if (audio) {
      audio.playsInline = true
      audio?.play()?.catch(err => {
        //Permission issue
      })
    }
  }

  React.useEffect(() => {
    if (socket && !socketEventAdded) {
      socket?.emit(
        'routeChange',
        JSON.stringify({
          route: router.asPath,
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            id: user.id,
            email: user.email,
          },
        }),
      )
      socket.onAny((eventName, ...args) => {})

      socket.on('message', ({ command, data }) => {
        if (command === 'triggerConfetti') {
          setTriggerConfetti(true)
          if (data && data.style) {
            setConfettiStyle(data.style)
          }
        }
      })
      socket.on('notification', msg => {
        const notif = JSON.parse(msg)

        if (notif.service === 'MemberNotificationService') {
          notifyMember(notif)
          playAudio()
          return
        }

        if (lct) {
          if (lct.enabled && lct.types[notif.type]) {
            notif.type = notif.activityType
            playAudio()
            notify(notif)
            setResetNotifications(new Date().getTime())

            // setNotifications(prev => [notif, ...prev])
          }
        }
      })

      socket.on('connect_error', async err => {
        // true
        // not authorized
        // { content: "Please retry later" }

        try {
          const res = await refreshToken()
          if (res.data.accessToken) {
            localStorage.setItem('accessToken', res.data.accessToken)
            socket.close()
            socket = initSocket(localStorage.getItem('accessToken'))
          }
        } catch (err) {}
      })

      setSocketEventAdded(true)
    }
  }, [socket])

  useEffect(() => {
    if (!socket) {
      initSocket(localStorage.getItem('accessToken'))
    }
  }, [])

  const emit = React.useCallback(
    evt => {
      if (socket) {
        socket?.emit(
          'routeChange',
          JSON.stringify({
            route: evt?.split('?')?.[0],
            user: {
              firstName: user.firstName,
              lastName: user.lastName,
              id: user.id,
              email: user.email,
            },
          }),
        )
      }
    },
    [socket],
  )

  useEffect(() => {
    // subscribe
    router.events.on('routeChangeComplete', emit)

    // unsubscribe
    return () => router.events.off('routeChangeComplete', emit)
  }, [emit, router.events])

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (triggerConfetti) {
      timeout = setTimeout(() => {
        setTriggerConfetti(false)
      }, 10000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [socket, triggerConfetti])

  return (
    <>
      <Confetti
        recycle={true}
        numberOfPieces={triggerConfetti ? 200 : 0}
        style={{ zIndex: 9999, position: 'fixed' }}
        width={windowSize[0]}
        height={windowSize[1]}
        {...(confettiStyle && { drawShape: drawingFunctions[confettiStyle] })}
      />

      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280,
          },
          width: {
            lg: 'calc(100% - 280px)',
          },
        }}
        {...other}
      >
        {user.impersonatedBy && <ImpersonateBanner user={user} />}
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none',
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Support">
            <IconButton
              onClick={() => {
                setHelpDialogOpen(true)
              }}
            >
              <HelpIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {process.env.NODE_ENV === 'development' && <TenantSwitcher />}
          {<ContentSearchButton />}
          {<ContactsButton />}

          {
            <NotificationsButton
              resetNotifications={resetNotifications}
              notifications={notifications}
              setNotifications={setNotifications}
            />
          }
          {<AccountButton />}
        </Toolbar>
        <audio ref={audioPlayer} src={sound} />
      </DashboardNavbarRoot>
      <HelpFormDialog
        onClose={() => {
          setHelpDialogOpen(false)
        }}
        open={helpDialogOpen}
      />
    </>
  )
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}
