import type { FC } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import NextLink from 'next/link'
import { UserCircle as UserCircleIcon } from 'icons/user-circle'
import { SwitchHorizontalOutlined as SwitchHorizontalOutlinedIcon } from 'icons/switch-horizontal-outlined'
import { Cog as CogIcon } from 'icons/cog'
import LogoutIcon from '@mui/icons-material/Logout'
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material'

import { useAuth } from '../../store/useAuth'
import { getTenantMetaData } from '../../tenant'
import api from '../../utils/api'
import { getInitials } from '../../utils/get-initials'
import { getMemberProfileImageUrl } from 'tenant'

interface AccountPopoverProps {
  anchorEl: null | Element
  onClose?: () => void
  open?: boolean
}

export const AccountPopover: FC<AccountPopoverProps> = props => {
  const { anchorEl, onClose, open, ...other } = props
  const router = useRouter()
  const { logout } = useAuth()
  const user = useAuth(state => state.user)

  const handleLogout = async (): Promise<void> => {
    try {
      await api.get('/oauth/logout')
      localStorage.removeItem('accessToken')

      window.location.href = '/'
    } catch (err) {
      console.error(err)
      toast.error('Unable to logout.')
    }
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}
      >
        <Avatar
          src={
            user.profileImage
              ? getMemberProfileImageUrl({
                  memberId: user.id,
                  width: 100,
                  profileImage: user.profileImage,
                })
              : ''
          }
          sx={{
            height: 40,
            width: 40,
          }}
        >
          {getInitials(`${user.firstName} ${user.lastName}`)}
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {getTenantMetaData().title}
          </Typography>
        </Box>
      </Box>
      {/* <Divider /> */}
      <Box sx={{ my: 1 }}>
        <NextLink href="/account?tab=general" passHref>
          <MenuItem component="a">
            <ListItemIcon>
              <UserCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Profile</Typography>}
            />
          </MenuItem>
        </NextLink>

        <Divider />
        <Box mt={1} />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Logout</Typography>}
          />
        </MenuItem>
      </Box>
    </Popover>
  )
}

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}
