import { FC, useState, useEffect } from 'react'
import { useAuth } from 'store/useAuth'
import { useFeatureFlags } from 'lib/features/hooks/useFeatureFlags'
import {
  DEFAULT_MULTI_UPGRADE_PLAN,
  PLANS,
  PLAN_MAPPING,
} from 'lib/upgrade-plans'
import { ProductSelectionDialog } from './product-selection-dialog'
import type { Plan } from 'lib/upgrade-plans'
import {
  Box,
  Button,
  Card,
  CardContent,
  SvgIcon,
  Typography,
} from '@mui/material'
import { Upgrade as UpgradeIcon } from '@mui/icons-material'
import { ProfileUpgradeDialog } from './profile-upgrade-dialog'

const UpgradeCard: FC<{
  title: string
  description: string | React.ReactNode
  buttonText: string
  onUpgrade: () => void
}> = ({ title, description, buttonText, onUpgrade }) => (
  <Card>
    <CardContent>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography
        color="textSecondary"
        sx={{
          backgroundColor: 'background.default',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
          flexGrow: 1,
          p: 1,
          my: 1,
        }}
        variant="body2"
      >
        {description}
      </Typography>
      <Button
        fullWidth
        onClick={onUpgrade}
        startIcon={
          <SvgIcon>
            <UpgradeIcon />
          </SvgIcon>
        }
        variant="contained"
        color="primary"
      >
        {buttonText}
      </Button>
    </CardContent>
  </Card>
)

export const ProfileUpgrade: FC = () => {
  const [openProductSelection, setOpenProductSelection] = useState(false)
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null)
  const [userHasDismissed, setUserHasDismissed] = useState<boolean>(false)
  const user = useAuth(state => state.user)
  const flags = useFeatureFlags(user?.subscriptions, user?.brand)

  const status = flags?.getSubscriptionStatus()
  const upgradePath = status?.upgradePath
  console.log(status, upgradePath)
  const handleOpenProductSelection = () => {
    setOpenProductSelection(true)
  }

  const handleCloseProductSelection = () => {
    setOpenProductSelection(false)
    setUserHasDismissed(true)
  }

  const handleSelectProduct = (plan: Plan) => {
    setSelectedPlan(plan)
    setOpenProductSelection(false)
    setOpenUpgradeDialog(true)
    setUserHasDismissed(true)
  }

  const handleCloseUpgradeDialog = () => {
    setOpenUpgradeDialog(false)
    setSelectedPlan(null)
  }
  const isFreeUser = flags?.isFreeUser()
  const isPaidUser = flags?.isPaidUser()
  console.log('is paid:', isPaidUser)
  console.log('is free:', isFreeUser)
  const isNotFreeOrPaidUser = !isFreeUser && !isPaidUser

  if (!flags || !upgradePath?.options.length || isNotFreeOrPaidUser) {
    return null
  }

  // If there's only one upgrade option, show its card directly
  if (upgradePath.options.length === 1) {
    const planKey = PLAN_MAPPING[upgradePath.options[0].name]
    const plan = PLANS[planKey]
    useEffect(() => {
      if (user && upgradePath?.options.length) {
        const defaultPlan = PLANS[PLAN_MAPPING[upgradePath.options[0].name]]
        console.log(selectedPlan)
        const dontShowAgain =
          localStorage.getItem(`dontShowAgain-${defaultPlan.id}`) === 'true'

        if (!dontShowAgain && !userHasDismissed) {
          handleSelectProduct(plan)
        }
      }
    }, [user, status, upgradePath])

    return (
      <>
        <UpgradeCard
          title={plan?.title}
          description={plan?.description}
          buttonText={plan?.buttonText}
          onUpgrade={() => handleSelectProduct(plan)}
        />

        {selectedPlan && (
          <ProfileUpgradeDialog
            open={openUpgradeDialog}
            handleClose={handleCloseUpgradeDialog}
            id={selectedPlan.id}
            title={selectedPlan.title}
            description={selectedPlan.description}
            buttonText={selectedPlan.buttonText}
            dontShowModalAgain={selectedPlan.dontShowModalAgain}
            plan={{
              name: selectedPlan.name,
              addons: selectedPlan.addons || [],
              features: selectedPlan.features,
            }}
          />
        )}
      </>
    )
  } else {
    useEffect(() => {
      if (user && upgradePath?.options.length) {
        console.log('weee')
        const defaultPlan = PLANS[PLAN_MAPPING[upgradePath.options[0].name]]
        console.log(selectedPlan)
        const dontShowAgain =
          localStorage.getItem(`dontShowAgain-${defaultPlan.id}`) === 'true'

        if (!dontShowAgain && !userHasDismissed) {
          handleOpenProductSelection()
        }
      }
    }, [user, status, upgradePath])
  }

  // For multiple upgrade options, show the default multi-upgrade card
  const multiUpgradePlan = {
    ...DEFAULT_MULTI_UPGRADE_PLAN,
    // Create bullet points from available upgrades
    description: (
      <>
        {DEFAULT_MULTI_UPGRADE_PLAN.description}
        <Box component="ul" sx={{ mt: 2, pl: 2 }}>
          {upgradePath.options.slice(0, 3).map(option => (
            <Box component="li" key={option.name} sx={{ mb: 1 }}>
              <strong>{option.name}</strong> - {option.description}
            </Box>
          ))}
        </Box>
      </>
    ),
  }

  return (
    <>
      <UpgradeCard
        title={multiUpgradePlan.title}
        description={multiUpgradePlan.description}
        buttonText={multiUpgradePlan.buttonText}
        onUpgrade={handleOpenProductSelection}
      />

      <ProductSelectionDialog
        open={openProductSelection}
        onClose={handleCloseProductSelection}
        upgradePath={upgradePath}
        onSelectProduct={handleSelectProduct}
      />

      {selectedPlan && (
        <ProfileUpgradeDialog
          open={openUpgradeDialog}
          handleClose={handleCloseUpgradeDialog}
          id={selectedPlan.id}
          title={selectedPlan.title}
          description={selectedPlan.description}
          buttonText={selectedPlan.buttonText}
          dontShowModalAgain={selectedPlan.dontShowModalAgain}
          plan={{
            name: selectedPlan.name,
            addons: selectedPlan.addons || [],
            features: selectedPlan.features,
          }}
        />
      )}
    </>
  )
}
