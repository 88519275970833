import { Branding } from '@realty/portal'

// types.ts
type SubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'pending'
  | 'expired'
  | 'stopped'

type SubscriptionType =
  | 'EXCLUSIVE'
  | 'VAPBASIC'
  | 'VAPEPIQUE'
  | 'VAPPREMIUM'
  | string

// Define available products/features
export type Product = 'REALTY_PRO' | 'REALTY_BASIC' | 'SELLER_PRO' | 'EPIQUE'

type Feature = 'seller_pro' | 'realty_pro' | 'leads' | string
type SubscriptionTier = 'free' | 'epique' | 'paid' | 'exclusive' | 'premium'

export type UpgradeOption = {
  name: string
  tier: SubscriptionTier
  products: readonly Product[]
  description: string
}

type ProductRequirement = {
  anyOf?: readonly Product[] // Products where any one must be present
  allOf?: readonly Product[] // Products where all must be present
  noneOf?: readonly Product[] // Products that must not be present
}

// Update TierConfig type
type TierConfig = {
  name: SubscriptionTier
  rank: number
  availableUpgrades: Array<{
    option: UpgradeOption
    requirements?: ProductRequirement
  }>
}

export type UpgradePath = {
  currentTier: SubscriptionTier
  options: UpgradeOption[]
}

interface SubscriptionMeta {
  discount?: number
  realty_id?: string
  term_start?: string
  subscription_status?: string
}

interface SubscriptionLineItem {
  id: number
  name: string
  features: Record<string, boolean> | null
  quantity: number
  rate: string
  discount: string
  total: string
  locationId?: number | null
  locationType?: string | null
  deletedAt: string | null
}

export interface Subscription {
  id: number
  status: SubscriptionStatus
  type: SubscriptionType
  meta: SubscriptionMeta
  amount: string
  balance: string
  subscriptionLineItems: SubscriptionLineItem[]
  lastInvStatus: string
}

interface BillingTotals {
  totalAmount: number
  totalBalance: number
  totalBilled: number // Sum of all line item totals
  totalDiscounts: number // Sum of all discounts
  activeSubscriptions: number
  pendingSubscriptions: number
  lineItemCount: number
}

class FeatureFlags {
  private subscription: Subscription[]
  private subscriptions: Subscription[]
  private brand: any

  private static readonly SUBSCRIPTION_HIERARCHY = ['VAPBASIC', 'EXCLUSIVE']

  private static readonly PRODUCT_NAMES = {
    REALTY_PRO: 'Verified Agent Plan Pro',
    REALTY_BASIC: 'Verified Agent Plan Basic',
    SELLER_PRO: 'Seller Pro',
    EPIQUE: 'epique',
  } as const

  private static readonly UNICORN_PLANS = {
    VAPEPIQUE: 'epique',
    VAPPREMIUM: 'premium',
  } as const

  private static readonly UPGRADE_OPTIONS = {
    REALTY_PRO: {
      name: 'Realty Pro',
      tier: 'paid' as const,
      products: ['REALTY_PRO'] as const,
      description: 'Professional tools for real estate agents',
    },
    REALTY_PRO_SELLER: {
      name: 'Realty Pro + Seller Pro Bundle',
      tier: 'paid' as const,
      products: ['REALTY_PRO', 'SELLER_PRO'] as const,
      description: 'Complete suite of professional tools',
    },
    SELLER_PRO_ADDON: {
      name: 'Seller Pro Add-on',
      tier: 'paid' as const,
      products: ['SELLER_PRO'] as const,
      description: 'Advanced seller tools and analytics',
    },
    EPIQUE: {
      name: 'Seller Pro + epique Bundle',
      tier: 'paid' as const,
      products: ['EPIQUE'] as const,
      description: 'epique',
    },
  } as const

  private static readonly TIER_CONFIG: Record<SubscriptionTier, TierConfig> = {
    free: {
      name: 'free',
      rank: 0,
      availableUpgrades: [
        { option: FeatureFlags.UPGRADE_OPTIONS.REALTY_PRO },
        { option: FeatureFlags.UPGRADE_OPTIONS.REALTY_PRO_SELLER },
      ],
    },
    epique: {
      name: 'epique',
      rank: 0,
      availableUpgrades: [
        {
          option: FeatureFlags.UPGRADE_OPTIONS.EPIQUE,
          requirements: {
            anyOf: ['EPIQUE'] as const,
            noneOf: ['SELLER_PRO'] as const,
          },
        },
      ],
    },
    paid: {
      name: 'paid',
      rank: 1,
      availableUpgrades: [
        {
          option: FeatureFlags.UPGRADE_OPTIONS.SELLER_PRO_ADDON,
          requirements: {
            anyOf: ['REALTY_PRO', 'REALTY_BASIC'] as const,
            noneOf: ['SELLER_PRO'] as const,
          },
        },
      ],
    },
    exclusive: {
      name: 'exclusive',
      rank: 2,
      availableUpgrades: [],
    },
    premium: {
      name: 'premium',
      rank: 3,
      availableUpgrades: [
        {
          option: FeatureFlags.UPGRADE_OPTIONS.SELLER_PRO_ADDON,
          requirements: {
            anyOf: ['REALTY_PRO', 'REALTY_BASIC'] as const,
            noneOf: ['SELLER_PRO'] as const,
          },
        },
      ],
    },
  } as const

  constructor(subscriptions: Subscription | Subscription[], brand: any) {
    this.subscriptions = Array.isArray(subscriptions)
      ? subscriptions
      : [subscriptions]
    this.brand = brand
  }

  private hasActiveSubscription(): boolean {
    if (this.isUnicorn().status) return true
    return this.subscriptions.some(sub => sub.status === 'active')
  }

  private getHighestTierSubscription(): Subscription | null {
    const activeSubscriptions = this.subscriptions.filter(
      sub => sub.status === 'active',
    )
    if (!activeSubscriptions.length) return null

    return activeSubscriptions.reduce((highest, current) => {
      const currentTierIndex = FeatureFlags.SUBSCRIPTION_HIERARCHY.indexOf(
        current.type,
      )
      const highestTierIndex = FeatureFlags.SUBSCRIPTION_HIERARCHY.indexOf(
        highest.type,
      )

      return currentTierIndex > highestTierIndex ? current : highest
    }, activeSubscriptions[0])
  }

  isPaidUser(): boolean {
    if (!this.hasActiveSubscription()) return false

    if (this.isUnicorn().status) return true
    // Check if any subscription is EXCLUSIVE
    if (this.hasExclusive()) {
      return true
    }

    // Check if any subscription has valid payment
    return this.subscriptions.some(sub => {
      const amount = parseFloat(sub.amount) || 0
      const balance = parseFloat(sub.balance) || 0

      const hasValidLineItems = sub.subscriptionLineItems.some(item => {
        if (item.deletedAt) return false
        const rate = parseFloat(item.rate) || 0
        const discount = parseFloat(item.discount) || 0
        const total = parseFloat(item.total) || 0
        return total > 0 || (rate > 0 && rate === discount)
      })

      return amount > 0 || balance > 0 || hasValidLineItems
    })
  }

  isFreeUser(): boolean {
    const isVapFree = this.brand?.data?.verified_agent_page?.plan === 'VAPFREE'
    const isUnicorn = this.isUnicorn().status
    return (
      (!this.hasActiveSubscription() && isVapFree) ||
      (this.isCanceledUser() && !isUnicorn)
    )
  }

  isCanceledUser(): boolean {
    if (this.subscriptions.length > 0) {
      //check all are stopped
      return this.subscriptions.every(sub => sub.status === 'stopped')
    }
    return false
  }

  getTierStatus(): SubscriptionTier {
    if (!this.hasActiveSubscription()) {
      return 'free'
    }

    const highestSub = this.getHighestTierSubscription()
    if (highestSub?.type === 'EXCLUSIVE') {
      return 'exclusive'
    }

    if (this.isUnicorn().status)
      return this.isUnicorn().type as SubscriptionTier

    return this.isPaidUser() ? 'paid' : 'free'
  }

  isUnicorn() {
    const unicornPlans = ['VAPEPIQUE', 'VAPPREMIUM']
    const type = this.brand?.data?.verified_agent_page?.plan

    return {
      status: unicornPlans.includes(type),
      type: FeatureFlags.UNICORN_PLANS[type],
    }
  }

  hasRealtyPro(): boolean {
    if (!this.hasActiveSubscription()) return false
    if (this.isUnicorn().status) return true
    // If any subscription is EXCLUSIVE, they get everything
    if (this.hasExclusive()) {
      return true
    }

    // Check if any subscription has Realty Pro
    return this.subscriptions.some(sub =>
      sub.subscriptionLineItems.some(
        item =>
          item.name === FeatureFlags.PRODUCT_NAMES.REALTY_PRO &&
          !item.deletedAt,
      ),
    )
  }

  hasSellerPro(): boolean {
    if (!this.hasActiveSubscription()) return false

    // If any subscription is EXCLUSIVE, they get everything
    if (this.hasExclusive()) {
      return true
    }
    // Check if any subscription has Seller Pro
    return this.subscriptions.some(sub =>
      sub.subscriptionLineItems.some(
        item =>
          (item.features?.seller_pro === true ||
            item.name === FeatureFlags.PRODUCT_NAMES.SELLER_PRO) &&
          !item.deletedAt,
      ),
    )
  }

  hasExclusive(): boolean {
    //check if subscription type is exclusive and status is not stopped
    return this.subscriptions.some(
      sub => sub.type === 'EXCLUSIVE' && sub.status !== 'stopped',
    )
  }

  hasProduct(product: Product): boolean {
    if (!this.hasActiveSubscription()) return false

    // If any subscription is EXCLUSIVE, they get everything
    if (this.hasExclusive()) {
      return true
    }
    console.log('product', product)
    // Check for specific products
    switch (product) {
      case 'REALTY_PRO':
        return this.hasRealtyPro()
      case 'REALTY_BASIC':
        return this.hasRealtyBasic()
      case 'SELLER_PRO':
        return this.hasSellerPro()
      case 'EPIQUE':
        return this.isUnicorn().status

      default:
        return false
    }
  }

  // Add method to check for basic plan
  hasRealtyBasic(): boolean {
    if (!this.hasActiveSubscription()) return false

    if (this.hasExclusive()) {
      return true
    }

    return this.subscriptions.some(sub =>
      sub.subscriptionLineItems.some(
        item =>
          item.name === FeatureFlags.PRODUCT_NAMES.REALTY_BASIC &&
          !item.deletedAt,
      ),
    )
  }

  private meetsProductRequirements(requirements?: ProductRequirement): boolean {
    if (!requirements) return true
    console.log('requirements', requirements)
    // Check if any of the required products are present
    const meetsAnyOf =
      !requirements.anyOf ||
      requirements.anyOf.some(product => this.hasProduct(product))

    // Check if all of the required products are present
    const meetsAllOf =
      !requirements.allOf ||
      requirements.allOf.every(product => this.hasProduct(product))

    // Check if none of the excluded products are present
    const meetsNoneOf =
      !requirements.noneOf ||
      requirements.noneOf.every(product => !this.hasProduct(product))

    return meetsAnyOf && meetsAllOf && meetsNoneOf
  }

  private hasValidInvoiceStatus(): boolean {
    return this.subscriptions.some(sub => {
      return (
        sub.status === 'active' && sub.lastInvStatus?.toLowerCase() === 'paid'
      )
    })
  }

  getUpgradePath(): UpgradePath | null {
    const currentTier = this.getTierStatus()
    const tierConfig = FeatureFlags.TIER_CONFIG[currentTier]
    console.log('tierConfig', tierConfig, currentTier)
    const availableOptions = tierConfig.availableUpgrades
      .filter(upgrade => this.meetsProductRequirements(upgrade.requirements))
      .map(upgrade => upgrade.option)

    if (availableOptions.length === 0) {
      return null
    }

    return {
      currentTier,
      options: availableOptions,
    }
  }
  getBillingTotals(): BillingTotals {
    const totals = this.subscriptions.reduce(
      (acc, subscription) => {
        // Only include active subscriptions in amounts
        if (subscription.status === 'active') {
          acc.activeSubscriptions += 1
          acc.totalAmount += parseFloat(subscription.amount) || 0
          acc.totalBalance += parseFloat(subscription.balance) || 0
        } else if (subscription.status === 'pending') {
          acc.pendingSubscriptions += 1
        }

        // Calculate line item totals
        subscription.subscriptionLineItems.forEach(item => {
          if (!item.deletedAt) {
            acc.lineItemCount += 1
            acc.totalBilled += parseFloat(item.total) || 0
            acc.totalDiscounts += parseFloat(item.discount) || 0
          }
        })

        return acc
      },
      {
        totalAmount: 0,
        totalBalance: 0,
        totalBilled: 0,
        totalDiscounts: 0,
        activeSubscriptions: 0,
        pendingSubscriptions: 0,
        lineItemCount: 0,
      },
    )

    // Round all monetary values to 2 decimal places
    return {
      ...totals,
      totalAmount: Number(totals.totalAmount.toFixed(2)),
      totalBalance: Number(totals.totalBalance.toFixed(2)),
      totalBilled: Number(totals.totalBilled.toFixed(2)),
      totalDiscounts: Number(totals.totalDiscounts.toFixed(2)),
    }
  }

  getSubscriptionStatus(): {
    isActive: boolean
    hasRealtyPro: boolean
    hasSellerPro: boolean
    isExclusive: boolean
    isPaid: boolean
    tier: SubscriptionTier
    upgradePath: UpgradePath | null
    billingTotals: BillingTotals
  } {
    const isActive = this.hasActiveSubscription()
    const highestSub = this.getHighestTierSubscription()

    return {
      isActive,
      hasRealtyPro: this.hasRealtyPro() || this.isUnicorn().status,
      hasSellerPro: this.hasSellerPro(),
      isExclusive: this.hasExclusive(),
      isPaid: this.isPaidUser() && this.hasValidInvoiceStatus(),
      tier: this.getTierStatus(),
      upgradePath: this.getUpgradePath(),
      billingTotals: this.getBillingTotals(),
    }
  }

  canAccessFeature(feature: Feature): boolean {
    if (!this.hasActiveSubscription()) return false
    if (this.isUnicorn().status) return true
    if (this.hasExclusive()) return true

    if (
      ['premium_feature', 'advanced_tools'].includes(feature) &&
      !this.isPaidUser()
    ) {
      return false
    }

    switch (feature) {
      case 'seller_pro':
        return this.hasSellerPro()
      case 'realty_pro':
        return this.hasRealtyPro()
      case 'leads':
        return false
      case 'epique':
        return this.hasRealtyPro()
      default:
        return false
    }
  }

  canAccessLeads(): boolean {
    return this.hasActiveSubscription() && this.hasExclusive()
  }

  getLocationLeads(cityId: number): number {
    if (!this.canAccessLeads()) return 0

    // Sum up leads across all subscriptions for the city
    return this.subscriptions.reduce((total, sub) => {
      const locationItem = sub.subscriptionLineItems.find(
        item => item.locationId === cityId && !item.deletedAt,
      )
      return total + (locationItem?.quantity ?? 0)
    }, 0)
  }
}

export default FeatureFlags
