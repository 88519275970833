const executeActions = async (
    actions: any[],
    executeStep: (step: any) => Promise<void>,
  ) => {
    for (const action of actions) {
      const { type, delay, selector, value, typingDelay, step } = action

      if (delay) {
        await new Promise(resolve => setTimeout(resolve, delay))
      }

      if (type === 'innerStep' && step) {
        await executeStep(step)
      } else {
        const element = document.querySelector(selector)

        if (element) {
          switch (type) {
            case 'click':
              ;(element as HTMLElement).click()
              break
            case 'typing':
              await typeText(
                element as HTMLInputElement | HTMLTextAreaElement,
                value,
                typingDelay || 100,
              )
              break
            // Add more action types as needed
            default:
              break
          }
        }
      }
    }
  }
  const setNativeValue = (element, value) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  }
  const typeText = async (element, text, delay) => {
    element.focus();
    element.click();
    setNativeValue(element, '');

    for (const char of text) {
      setNativeValue(element, element.value + char);

      // Dispatch the input event
      const inputEvent = new InputEvent('input', {
        bubbles: true,
        cancelable: true,
        inputType: 'insertText',
        data: char,
      });
      element.dispatchEvent(inputEvent);

      // Dispatch keyboard events
      const keyEventOptions = { key: char, bubbles: true, cancelable: true };
      element.dispatchEvent(new KeyboardEvent('keydown', keyEventOptions));
      element.dispatchEvent(new KeyboardEvent('keypress', keyEventOptions));
      element.dispatchEvent(new KeyboardEvent('keyup', keyEventOptions));

      // Trigger React's synthetic events
      const nativeInputEvent = new Event('input', { bubbles: true, cancelable: true });
      element.dispatchEvent(nativeInputEvent);

      await new Promise(resolve => setTimeout(resolve, delay));
    }

    // Dispatch a final change event
    const changeEvent = new Event('change', { bubbles: true, cancelable: true });
    element.dispatchEvent(changeEvent);

    element.blur();
  };

  export default executeActions
