import React from 'react'
import toast from 'react-hot-toast'
import { Transition } from 'react-transition-group'
import NextLink from 'next/link'
import { Box, Button, Card, Typography } from '@mui/material'
import { RecentActivity } from 'components/lead-details/recent-activity'
import { format, parseISO } from 'date-fns'
import { useQueryClient } from 'react-query'

// import { RecentActivity } from './recent-activity-old'

const duration = 300

const defaultStyle: any = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles: any = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const entityToLinkMap = {
  member: 'members',
  cone_zone: 'cone_zone',
  brand_group: 'groups',
  customer: 'cone_zone',
  brand: 'brands',
}

const useMemberNotificationAlert = () => {
  const queryClient = useQueryClient()

  const notify = notification => {
    let link = '/'

    const { data } = notification

    if (data?.type === 'tasks') {
      link = `/${entityToLinkMap[data.entityType]}/${
        data.entityId
      }?tab=tasks&entityId=${data.entityId}&entityType=${
        data.entityType
      }&epicId=${data.epicId}`
      queryClient.refetchQueries('getEpicDetail')
    }

    return toast.custom(t => (
      <Transition in={t.visible ? true : false} timeout={duration}>
        {state => {
          return (
            <Card
              elevation={23}
              sx={{
                zIndex: 9999,
                maxWidth: 650,
                borderRadius: 6,

                backgroundColor: 'background.paper',
                display: 'flex',
              }}
            >
              <Box py={3} px={5}>
                <NextLink href={link}>
                  <Typography sx={{ cursor: 'pointer' }} variant="body1">
                    {notification.title}
                  </Typography>
                </NextLink>
                <Typography color="textSecondary" variant="caption">
                  {format(
                    parseISO(
                      notification.createdAt ?? new Date().toISOString(),
                    ),
                    'dd MMM,yyyy hh:mm aaaa',
                  )}
                </Typography>
              </Box>
              <Box>
                <Button onClick={e => toast.remove(t.id)}>Close</Button>
              </Box>
            </Card>
          )
        }}
      </Transition>
    ))
  }

  return { notify }
}
export default useMemberNotificationAlert
