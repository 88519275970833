import React from 'react'
import { useAuth } from 'store/useAuth'
import { useFeatureFlags } from 'lib/features/hooks/useFeatureFlags'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Grid,
  Paper,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from '@mui/material'
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  AttachMoney as AttachMoneyIcon,
  MoneyOff as MoneyOffIcon,
  Star as StarIcon,
  ArrowUpward as ArrowUpwardIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import numeral from 'numeral'

const StatusChip = ({ active, label }) => (
  <Chip
    icon={active ? <CheckCircleIcon /> : <CancelIcon />}
    label={label}
    color={active ? 'success' : 'default'}
    variant="outlined"
    size="small"
  />
)
const BillingTotalsSection = ({ totals }: { totals: BillingTotals }) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h6" gutterBottom>
      Billing Summary
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Financial Summary
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Total Amount</TableCell>
                <TableCell align="right">
                  <Typography color="primary" fontWeight="bold">
                    ${totals.totalAmount.toLocaleString()}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Billed</TableCell>
                <TableCell align="right">
                  ${totals.totalBilled.toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Discounts</TableCell>
                <TableCell align="right">
                  ${totals.totalDiscounts.toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Outstanding Balance</TableCell>
                <TableCell align="right">
                  <Typography
                    color={totals.totalBalance > 0 ? 'error' : 'success'}
                  >
                    ${totals.totalBalance.toLocaleString()}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Subscription Summary
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Active Subscriptions</TableCell>
                <TableCell align="right">
                  <Chip
                    label={totals.activeSubscriptions}
                    color="success"
                    size="small"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pending Subscriptions</TableCell>
                <TableCell align="right">
                  <Chip
                    label={totals.pendingSubscriptions}
                    color="warning"
                    size="small"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Line Items</TableCell>
                <TableCell align="right">
                  <Chip
                    label={totals.lineItemCount}
                    color="primary"
                    size="small"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  </Box>
)

const UpgradePathSection = ({ upgradePath }) => {
  if (!upgradePath) return null

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Available Upgrades
      </Typography>
      <Grid container spacing={2}>
        {upgradePath.options.map(option => (
          <Grid
            item
            xs={12}
            md={upgradePath.options.length > 1 ? 6 : 12}
            key={option.name}
          >
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {option.name}
                  </Typography>
                  <Chip
                    icon={<ArrowUpwardIcon />}
                    label={option.tier}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Products Included:
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                    {option.products.map(product => (
                      <Chip
                        key={product}
                        label={product}
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

const TierChip = ({ tier }) => {
  const config = {
    exclusive: {
      icon: <StarIcon />,
      color: 'primary',
      label: 'Exclusive',
    },
    paid: {
      icon: <AttachMoneyIcon />,
      color: 'success',
      label: 'Paid',
    },
    free: {
      icon: <MoneyOffIcon />,
      color: 'default',
      label: 'Free',
    },
    epique: {
      icon: <MoneyOffIcon />,
      color: 'default',
      label: 'Free',
    },
    premium: {
      icon: <MoneyOffIcon />,
      color: 'default',
      label: 'Premium Trial',
    },
  }[tier]

  return (
    <Chip
      icon={config.icon}
      label={config.label}
      color={config.color}
      variant="outlined"
    />
  )
}

const SubscriptionCard = ({ subscription }) => (
  <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          pr: 2,
        }}
      >
        <Typography variant="subtitle1">
          ID: {subscription.id} - {subscription.type}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Chip
            label={subscription.status}
            color={subscription.status === 'active' ? 'success' : 'default'}
            size="small"
          />
          <Chip
            label={numeral(subscription.amount).format('$0,0')}
            color="primary"
            size="small"
          />
        </Stack>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th">Status</TableCell>
              <TableCell>{subscription.status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Amount</TableCell>
              <TableCell>${subscription.amount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Balance</TableCell>
              <TableCell>${subscription.balance}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Last Invoice</TableCell>
              <TableCell>{subscription.lastInvStatus}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Line Items */}
      <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
        Line Items
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Features</TableCell>
              <TableCell align="right">Rate</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscription.subscriptionLineItems.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {item.features ? (
                    <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                      {Object.entries(item.features).map(([key, value]) => (
                        <Chip
                          key={key}
                          label={`${key}: ${String(value)}`}
                          size="small"
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  ) : (
                    <Typography color="text.secondary" variant="body2">
                      No features
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="right">${item.rate}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">${item.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionDetails>
  </Accordion>
)

const SubscriptionDebugView = () => {
  const user = useAuth(state => state.user)
  const flags = useFeatureFlags(user?.subscriptions, user?.brand)

  if (!flags || !user?.subscriptions) return null

  const status = flags.getSubscriptionStatus()
  const subscriptions = Array.isArray(user.subscriptions)
    ? user.subscriptions
    : [user.subscriptions]
  const upgradePath = flags.getUpgradePath()

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', p: 2 }}>
      <Card>
        <CardContent>
          {/* Aggregate Status Header */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            Subscription Status (Aggregate)
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TierChip tier={status.tier} />
              <Chip
                label={status.isActive ? 'Active' : 'Inactive'}
                color={status.isActive ? 'success' : 'error'}
              />
            </Box>
          </Typography>

          {/* Feature Access Summary */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Feature Access (Across All Subscriptions)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Product Access
                  </Typography>
                  <Stack spacing={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>Realty Pro</Typography>
                      <StatusChip
                        active={status.hasRealtyPro}
                        label={status.hasRealtyPro ? 'Yes' : 'No'}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>Seller Pro</Typography>
                      <StatusChip
                        active={status.hasSellerPro}
                        label={status.hasSellerPro ? 'Yes' : 'No'}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>Exclusive Access</Typography>
                      <StatusChip
                        active={status.isExclusive}
                        label={status.isExclusive ? 'Yes' : 'No'}
                      />
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Payment Status
                  </Typography>
                  <Stack spacing={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>Paid Status</Typography>
                      <StatusChip
                        active={status.isPaid}
                        label={status.isPaid ? 'Paid' : 'Unpaid'}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>Leads Access</Typography>
                      <StatusChip
                        active={flags.canAccessLeads()}
                        label={flags.canAccessLeads() ? 'Yes' : 'No'}
                      />
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          {/* Available Upgrade Paths */}
          {upgradePath && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Available Upgrades
              </Typography>
              <Grid container spacing={2}>
                {upgradePath.options.map(option => (
                  <Grid item xs={12} md={6} key={option.name}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="subtitle1" fontWeight="bold">
                            {option.name}
                          </Typography>
                          <Chip
                            icon={<ArrowUpwardIcon />}
                            label={option.tier}
                            color="primary"
                            variant="outlined"
                            size="small"
                          />
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          {option.description}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          <BillingTotalsSection totals={status.billingTotals} />

          {/* Individual Subscriptions */}
          <Typography variant="h6" gutterBottom>
            Individual Subscriptions ({subscriptions.length})
          </Typography>
          <Stack spacing={2}>
            {subscriptions.map(subscription => (
              <SubscriptionCard
                key={subscription.id}
                subscription={subscription}
              />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}

export default SubscriptionDebugView
