import type { FC } from 'react'
import { Avatar, MenuItem, Popover } from '@mui/material'

import { getInitials } from '../../utils/get-initials'

interface OrganizationPopoverProps {
  anchorEl: null | Element
  onClose?: () => void
  open?: boolean
}

const organizations = ['Realty.com', 'HomeScout.com']

export const OrganizationPopover: FC<OrganizationPopoverProps> = props => {
  const { anchorEl, onClose, open, ...other } = props

  const handleChange = (organization: string): void => {
    onClose?.()
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 248 } }}
      transitionDuration={0}
      {...other}
    >
      {organizations.map(organization => (
        <MenuItem key={organization} onClick={() => handleChange(organization)}>
          <Avatar
            sx={{
              height: 22,
              width: 22,
            }}
            style={{ marginRight: 8 }}
          >
            {getInitials(organization)}
          </Avatar>
          {organization}
        </MenuItem>
      ))}
    </Popover>
  )
}
