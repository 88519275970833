import React, { useEffect, useRef, useState } from 'react'

import { Box, Button, Chip, Link, Typography } from '@mui/material'
import { Fingerprint } from '@mui/icons-material'
import api from 'utils/api'
import { useAuth } from 'store/useAuth'
import { Member } from 'types/members'
import { SubscriptionDebugDialog } from './subscription-debug-dialog'
interface ImpersonateBannerProps {
  user: Member
}

export const ImpersonateBanner: React.FC<ImpersonateBannerProps> = ({
  user,
}) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const setAccessToken = useAuth(state => state.setAccessToken)
  const exitImpersonation = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const result = await api.get('/oauth/impersonate/exit')
      setAccessToken(result.data.accessToken, result.data.expiresIn)
    } catch (err) {
      // toast.notifyError('Something went wrong');
    } finally {
      let lastPath = localStorage.getItem('lastPath')
      if (lastPath) {
        window.location.href = lastPath as string
        localStorage.removeItem('lastPath')
      } else {
        window.location.href = '/' as string
      }
    }
  }
  const hasSubscriptionDebug =
    user?.subscriptions && user?.subscriptions.length > 0
  console.log('hasSubscriptionDebug', hasSubscriptionDebug)
  return (
    <>
      <Box
        sx={{
          height: 42,
          backgroundColor: 'default',
          borderRadius: 0,
          borderBottom: 1,
          borderColor: 'divider',
        }}
        pt={1}
        pb={1}
        gap={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Fingerprint color="primary" />
        <Typography color="textPrimary" variant="subtitle2">
          You Are Impersonating{' '}
          {hasSubscriptionDebug ? (
            <Link
              onClick={() => setOpen(true)}
              component="button"
              variant="body2"
              sx={{ textDecoration: 'underline' }}
            >
              {user?.firstName} {user?.lastName}
            </Link>
          ) : (
            <span>
              {user?.firstName} {user?.lastName}
            </span>
          )}
        </Typography>
        <Chip
          disabled={loading}
          color="info"
          label="Leave"
          onClick={exitImpersonation}
          size="small"
          variant="outlined"
        />
      </Box>
      <SubscriptionDebugDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}
