import Markdown from 'markdown-to-jsx'
import NextLink from 'next/link'
import { getTenantMainSite } from 'tenant'
import { getInitials } from 'utils'

import { Avatar, Chip, Typography } from '@mui/material'
import { parseMessageForMention } from '@realty/portal'

function RenderMentionChip({ name, id }) {
  return (
    <NextLink href={`/members/${id}?tab=details`} passHref>
      <Chip
        avatar={
          <Avatar src={`${getTenantMainSite()}/member/profile-image/${id}`}>
            {getInitials(name)}
          </Avatar>
        }
        label={name}
        size="small"
        component="span"
        data-safe
      />
    </NextLink>
  )
}

function RenderMessage({ message }) {
  const parts = parseMessageForMention(message)
  return (
    <Typography variant="body2" color="inherit" data-safe>
      {parts.map((part, index) => {
        if (typeof part === 'string') {
          return (
            <Markdown
              options={{
                overrides: {
                  a: {
                    props: {
                      target: '_blank',
                      class: 'text-link',
                    },
                  },
                },
              }}
            >
              {part}
            </Markdown>
          )
        }
        return <RenderMentionChip key={index} {...part} />
      })}
    </Typography>
  )
}

export default RenderMessage
