import { FC, Fragment, ReactElement } from 'react'

import RenderMessage from 'components/mentions-text-field/render-message'
import { format, parseISO } from 'date-fns'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import numbro from 'numbro'
import Zoom from 'react-medium-image-zoom'
import { useAuth } from 'store/useAuth'
import { getLeadAttachmentUrl, getTenantMainSite } from 'tenant'
import {
  LeadDetailResponse,
  RecentActivity as RecentActivityInterface,
} from 'types/leads'
import { Member } from 'types/members'
import {
  abbreviateNumber,
  getInitials,
  getPropertyImage,
  isImage,
  replaceAndTransformText,
  setByDotNotation,
  stringToTitle,
} from 'utils'
import { flattenObject } from 'utils/flatten'

import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined'
import CalculateIcon from '@mui/icons-material/Calculate'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { scope } from '@realty/portal'

import { Calendar as CalendarIcon } from '../../icons/calendar'
import { Download as DownloadIcon } from '../../icons/download'
import { Eye as EyeIcon } from '../../icons/eye'
import { Mail as MailIcon } from '../../icons/mail'
import { Save as SaveIcon } from '../../icons/save'
import { SwitchHorizontalOutlined as SwitchHorizontalOutlinedIcon } from '../../icons/switch-horizontal-outlined'
import { UserAdd as UserAddIcon } from '../../icons/user-add'

const { config: scopeConfig } = scope

const keyMappings: { [key: string]: { [key: string]: string[] } } = {
  address: {
    street: ['propertyAddress', 'inquiryData.street_address'],
    city: ['propertyCity', 'inquiryData.city'],
    state: ['propertyState', 'inquiryData.state'],
    zip: ['propertyZipCode', 'inquiryData.zip'],
  },
}

export const getValueFromItem = (item: any, key: string, type: string) => {
  const keys = keyMappings[type][key]
  for (let key of keys) {
    // Use setByDotNotation to access the property using dot notation
    const value = setByDotNotation(item, key, null)
    if (value !== null) {
      return value
    }
  }
  return null
}

export const generateAddress = (activity): string => {
  const addressComponents: string[] = []
  const street = getValueFromItem(activity, 'street', 'address')
  const city = getValueFromItem(activity, 'city', 'address')
  const state = getValueFromItem(activity, 'state', 'address')
  const zip = getValueFromItem(activity, 'zip', 'address')

  street && addressComponents.push(`${stringToTitle(street)},`)
  city && addressComponents.push(`${city},`)
  state && addressComponents.push(`${state},`)
  zip && addressComponents.push(zip.toString())

  return addressComponents.join(' ').trim()
}

export const generateLocation = (activity): string => {
  const locationComponents: string[] = []

  const city = getValueFromItem(activity, 'city', 'address')
  const state = getValueFromItem(activity, 'state', 'address')

  city && locationComponents.push(`${city},`)
  state && locationComponents.push(state)

  return locationComponents.join(' ').trim()
}

export const generateLocationLabel = (activity: any): string => {
  const street = getValueFromItem(activity, 'street', 'address')
  const city = getValueFromItem(activity, 'city', 'address')

  if (street || city) {
    return street ? generateAddress(activity) : generateLocation(activity)
  }
  return ''
}

const getStreetAddress = (activity: any): string => {
  const street = getValueFromItem(activity, 'street', 'address')
  return street ? `${stringToTitle(street)},` : ''
}

const getActivityIcon: {
  [key: string]: ReactElement
} = {
  'showing-request': <CalendarIcon fontSize="small" />,
  'store-saved-property': <DownloadIcon fontSize="small" />,
  'viewed-property': <EyeIcon fontSize="small" />,
  'store-user-inquiry': <MailIcon fontSize="small" />,
  'store-saved-search': <SaveIcon fontSize="small" />,
  'delete-saved-search': <DeleteForeverIcon fontSize="small" />,
  'stage-change': <SwitchHorizontalOutlinedIcon fontSize="small" />,
  registration: <UserAddIcon fontSize="small" />,
  'store-lead-note': <NoteAddIcon fontSize="small" />,
  'store-lead-note-comment': <AddCommentOutlinedIcon fontSize="small" />,
  'delete-lead-note': <DeleteForeverIcon fontSize="small" />,
  'delete-lead-note-comment': <DeleteForeverIcon fontSize="small" />,
  'mortgage-calc': <CalculateIcon fontSize="small" />,
  'tcpa-set': <VerifiedUserIcon fontSize="small" />,
}

const tabsMap: { [key: string]: string } = {
  'showing-request': 'requests',
  'store-saved-property': 'properties',
  'viewed-property': 'properties',
  'store-user-inquiry': 'inquiries',
  'store-saved-search': 'searches',
  registration: 'details',
}

const stageMap: { [key: string]: string } = {
  dnc: 'Do Not Contact 🛑',
  stale: 'Stale Lead',
  hot_lead: 'Hot Lead 🔥',
  cold_lead: 'Cold Lead ❄️',
  warm: 'Warm Lead',
  dead_lead: 'Dead Lead 💀',
  new_lead: 'New Lead 🆕',
  raw_leads: 'Raw Lead',
  aged_leads: 'Aged Lead',
  email_only: 'Email Only 📧',
  transferred: 'Transferred',
  attempted_transfer: 'Attempted Warm Transfer',
  transfer_attempt: 'Incoming Transfer Attempt',
  nurture_lead: 'Nurture Lead',
  aged_attempting: 'Aged Attempting',
  appointment_set: 'Appointment Set 🗓️',
  attempt_contact: 'Attempting Contact',
  attempting_contact: 'Attempting Contact',
  high_opportunity: 'High Opportunity 🔥',
  missed_opportunity: 'Missed Opportunity',
  lost: 'Lost',
  closed: 'Closed',

  agreement: 'Agreement',
  contact_made: 'Contact Made',
  under_contract: 'Under Contract',

  attempted_contact: 'Attempted Contact',
}

const calcMap = {
  down: 'Down Payment',
  upfront: 'Down Payment %',
  loan: 'Total Loan',
  role: 'Role',
  term: 'Term',
  months: 'Months',
  rate: 'Rate',
  monthly: 'Monthly Payment',
}

const renderSavedSearchData = (data: [any, any], additionalData?: any) => {
  const formatCurrency = (value: any) =>
    numbro(value).formatCurrency({ average: true, mantissa: 0 })

  const formatNumber = (value: any) =>
    numbro(value).format({ thousandSeparated: true })

  const titleMap: { [key: string]: string } = {
    MinSquareFootage: 'Min Square Footage',
    MinBaths: 'Baths',
    MinBedrooms: 'Beds',
  }

  const [dataType, value] = data

  switch (dataType) {
    case 'locations':
      return `Location: ${value[0][0].name}`
    case 'PriceRange':
      const minPrice = additionalData.MinPrice
      const maxPrice = additionalData.MaxPrice
      if (minPrice && maxPrice) {
        return `Price Range: ${formatCurrency(minPrice)} - ${formatCurrency(
          maxPrice,
        )}`
      } else if (minPrice) {
        return `Price Range: ${formatCurrency(minPrice)}+`
      } else if (maxPrice) {
        return `Price Range: 0 - ${formatCurrency(maxPrice)}`
      }
      break
    case 'SqftRange':
      const minSqft = additionalData.MinSquareFootage
      const maxSqft = additionalData.MaxSquareFootage
      if (minSqft && maxSqft) {
        return `Sqft: ${formatNumber(minSqft)} - ${formatNumber(maxSqft)}`
      } else if (minSqft) {
        return `Sqft: ${formatNumber(minSqft)}+`
      } else if (maxSqft) {
        return `Sqft: 0 - ${formatNumber(maxSqft)}`
      }
      break
    case 'YearBuiltRange':
      const minYear = additionalData.MinYearBuilt
      const maxYear = additionalData.MaxYearBuilt
      if (minYear && maxYear) {
        return `Year Built: ${minYear} - ${maxYear}`
      } else if (minYear) {
        return `Year Built: ${minYear}+`
      } else if (maxYear) {
        return `Year Built: 0 - ${maxYear}`
      }
      break
    case 'MinBaths':
    case 'MinBedrooms':
    case 'stage-change':
    case 'property_types':
    case 'listing_types': {
      const key = dataType.replace(/_/g, '-')
      return `${titleMap[key] || key}: ${value}+`
    }
    default:
      return ''
  }
}

const box = (item: any) => ({
  swLat: item.savedSearchData?.SearchMapSwLat,
  swLong: item.savedSearchData?.SearchMapSwLong,
  neLat: item.savedSearchData?.SearchMapNeLat,
  neLong: item.savedSearchData?.SearchMapNeLong,
})

const getActivityContent = (
  activity: RecentActivityInterface,
  leadDetail: LeadDetailResponse,
  user: Member,
) => {
  switch (activity?.type?.toString()) {
    case 'registration':
      return {
        avatarImg: '',
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Registration</b>
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              {leadDetail.firstName} registered on{' '}
              {format(
                parseISO(activity.createdAt ?? new Date().toISOString()),
                'MMMM dd, yyyy',
              )}{' '}
              {activity.data.city &&
                `in ${activity.data.city}, ${activity.data.state}`}
            </Typography>
          </Box>
        ),
      }
    case 'viewed-property':
      const imageUrl = getPropertyImage(
        activity?.propertyListingId,
        activity?.photoCount,
        activity?.sourceSystemKey,
        activity?.propertyLatitude,
        activity?.propertyLongitude,
      )

      return {
        avatarImg: imageUrl,
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Property View</b> -{' '}
            <Chip
              label={`$${abbreviateNumber(activity.propertyListPrice)}`}
              size="small"
            />
            {activity.firstName && (
              <Typography color="textSecondary" variant="caption">
                {' - ' + activity.firstName + ' ' + activity.lastName}
              </Typography>
            )}
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              {generateLocationLabel(activity)}
            </Typography>
          </Box>
        ),
      }
    case 'showing-request':
      //   let message = activity.showingRequestData?.message
      //   const regexx = /at (.*?) on/
      //   const match = regexx.exec(message)

      //   if (match && match[1]) {
      //     const address = match[1].trim()
      //     const propertyListingId =
      //       activity.showingRequestData.property_listing_id
      //     const link = `<a target="_blank" href="${getTenantMainSite()}/property/${propertyListingId}" style="text-decoration:underline">${address}</a>`
      //     message = message.replace(address, link)
      //   }
      return {
        avatarImg: getPropertyImage(
          activity.propertyListingId,
          activity.photoCount,
          activity.sourceSystemKey,
          activity.propertyLatitude,
          activity.propertyLongitude,
        ),
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Showing Request</b>
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              <Card
                variant="outlined"
                sx={{
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  flexGrow: 1,
                  p: 2,
                  my: 1,
                }}
              >
                <Chip
                  icon={<RoomOutlinedIcon />}
                  label={generateLocationLabel(activity)}
                  size="small"
                  sx={{
                    mb: 1,
                  }}
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: replaceAndTransformText({
                        text: activity.showingRequestData.message,
                        start: 'at ',
                        end: ' on',
                        transform: extractedText => {
                          return `<a target="_blank" href="${getTenantMainSite()}/property/${
                            activity?.showingRequestData?.property_listing_id
                          }" style="text-decoration:underline">${extractedText}</a>`
                        },
                      }),
                    }}
                  ></Typography>
                </Box>
              </Card>
            </Typography>
          </Box>
        ),
      }
    case 'store-saved-property':
      return {
        avatarImg: getPropertyImage(
          activity.propertyListingId,
          activity.photoCount,
          activity.sourceSystemKey,
          activity.propertyLatitude,
          activity.propertyLongitude,
        ),
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Property Save</b>
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              {generateLocationLabel(activity)}
            </Typography>
          </Box>
        ),
      }
    case 'email-share':
      return {
        avatarImg: getPropertyImage(
          activity.propertyListingId,
          activity.photoCount,
          activity.sourceSystemKey,
          activity.propertyLatitude,
          activity.propertyLongitude,
        ),
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Property Share</b>
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              {stringToTitle(activity.propertyAddress)}
            </Typography>
          </Box>
        ),
      }
    case 'store-user-inquiry':
      return {
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Inquiry</b>
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              <Card
                variant="outlined"
                sx={{
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  flexGrow: 1,
                  p: 2,
                  my: 1,
                }}
              >
                <Chip
                  icon={<RoomOutlinedIcon />}
                  label={generateLocationLabel(activity)}
                  size="small"
                  sx={{
                    mb: 1,
                  }}
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: replaceAndTransformText({
                        text: activity.inquiryData?.message,
                        // exact: generateLocationLabel(activity),
                        start: getStreetAddress(activity),
                        end: activity.inquiryData.zip,
                        inclusive: true,
                        transform: extractedText => {
                          return `<a target="_blank" href="${getTenantMainSite()}/property/${
                            activity?.inquiryData?.property_listing_id
                          }" style="text-decoration:underline">${extractedText}</a>`
                        },
                      }),
                    }}
                  ></Typography>
                </Box>
              </Card>
            </Typography>
          </Box>
        ),
      }
    case 'store-saved-search':
      const keysToKeep = new Set([
        'SearchMapNeLong',
        'SearchMapSwLat',
        'SearchMapNeLat',
        'SearchMapSwLong',
        'page_uri',
        'IncludeClustersOrPins',
        'property_status',
        'extended_criteria',
        'property_types',
        'listing_types',
      ])

      const predefinedOrder = [
        'locations',
        'PriceRange',
        'SqftRange',
        'YearBuiltRange',
        'MinBaths',
        'MinBedrooms',
        'listing_types',
        'property_types',
      ]

      const filteredData = Object.entries(activity?.savedSearchData || {})
        .filter(([key]) => !keysToKeep.has(key))
        .sort((a, b) => {
          return predefinedOrder.indexOf(a[0]) - predefinedOrder.indexOf(b[0])
        })
        .reduce((obj, [key, value]) => {
          obj[key] = value
          return obj
        }, {})

      // Create a combined 'PriceRange' entry if MinPrice or MaxPrice exists
      if (
        activity.savedSearchData?.MinPrice ||
        activity.savedSearchData?.MaxPrice
      ) {
        filteredData.PriceRange = null
      }

      if (
        activity.savedSearchData?.MinSquareFootage ||
        activity.savedSearchData?.MinSquareFootage
      ) {
        filteredData.SqftRange = null
      }

      if (
        activity.savedSearchData?.MinYearBuilt ||
        activity.savedSearchData?.MinYearBuilt
      ) {
        filteredData.YearBuiltRange = null
      }

      // Render chips
      const chips = Object.entries(filteredData).map(([key, value]) => {
        const label = renderSavedSearchData(
          [key, value],
          activity.savedSearchData,
        )
        return label ? (
          <Chip key={key} label={label} size="small" sx={{ margin: '2px' }} />
        ) : null
      })

      return {
        //avatarImg: bboxToStaticMap(box(activity)),
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>
              Saved Search{' '}
              {activity.savedSearchName ? ` - ${activity.savedSearchName}` : ''}
              <Typography color="textSecondary" variant="caption">
                {activity.firstName &&
                  ` - ${activity.firstName} ${activity.lastName}`}
              </Typography>
            </b>
          </Box>
        ),
        component: <Box>{chips.length ? chips : 'No Data'}</Box>,
      }
    case 'delete-saved-search':
      return {
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Typography sx={{ mr: 0.5 }} variant="body2">
            <b>Search Deleted</b>
          </Typography>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="body2">
              <Typography color="textSecondary" variant="caption">
                {activity.firstName &&
                  ` - ${activity.firstName} ${activity.lastName}`}
              </Typography>
            </Typography>
          </Box>
        ),
      }
    case 'store-lead-note':
      return {
        scrollId: `note_${activity.data?.note_id}`,
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Note Added</b>
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  flexGrow: 1,
                  p: 2,
                  my: 1,
                }}
              >
                <Typography variant="body2" whiteSpace={'pre-line'}>
                  <RenderMessage
                    message={
                      activity?.note ?? activity?.noteData ?? '**note deleted**'
                    }
                  />
                </Typography>
              </Box>
            </Typography>
          </Box>
        ),
      }
    case 'store-lead-note-comment':
      return {
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Comment Added</b>
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  flexGrow: 1,
                  p: 2,
                  my: 1,
                }}
              >
                <Typography variant="body2">
                  {' '}
                  <RenderMessage
                    message={
                      activity?.comment ??
                      activity?.commentData ??
                      '**comment deleted**'
                    }
                  />
                </Typography>
                {activity?.commentMedia && isImage(activity?.commentMedia) && (
                  <Zoom classDialog="custom-zoom" zoomMargin={65}>
                    <img
                      src={getLeadAttachmentUrl({
                        leadId: activity.leadId,
                        media: activity.commentMedia,
                      })}
                      style={{
                        objectFit: 'contain',
                        borderRadius: '1rem',
                        marginTop: 24,
                        marginBottom: 20,
                        width: '100%',
                      }}
                    />
                  </Zoom>
                )}
              </Box>
            </Typography>
          </Box>
        ),
      }
    case 'delete-lead-note':
      return {
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Typography sx={{ mr: 0.5 }} variant="body2">
            <b>Note Deleted</b>
          </Typography>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="body2">
              <Typography color="textSecondary" variant="caption">
                {activity.firstName &&
                  ` - ${activity.firstName} ${activity.lastName}`}
              </Typography>
            </Typography>
          </Box>
        ),
      }
    case 'delete-lead-note-comment':
      return {
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Typography sx={{ mr: 0.5 }} variant="body2">
            <b>Comment Deleted</b>
          </Typography>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="body2">
              <Typography color="textSecondary" variant="caption">
                {activity.firstName &&
                  ` - ${activity.firstName} ${activity.lastName}`}
              </Typography>
            </Typography>
          </Box>
        ),
      }
    case 'stage-change':
      return {
        scope: scopeConfig.General.Leads.STAGE_CHANGE,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Stage Change</b>
            {activity.firstName && (
              <Typography color="textSecondary" variant="caption">
                {' - '}
                {`${activity.firstName} ${activity.lastName}`}
              </Typography>
            )}
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              Lead stage changed{activity?.data?.from && ' from '}
              {activity?.data?.from && <b>{stageMap[activity?.data?.from!]}</b>}
              {activity?.data?.to && ' to '}
              {activity?.data?.to && <b>{stageMap[activity?.data?.to!]}</b>}
            </Typography>
          </Box>
        ),
      }
    case 'reassign-lead':
      const regex =
        /Assigned to ([^(]+) \((\d+)\) by ([^(]+)(?: \(member_id: (\d+)\))?/

      const [, toName, toIdString, byName, byIdString] =
        activity?.data?.details.match(regex) || []
      const toId = parseInt(toIdString)
      const byId = byIdString ? parseInt(byIdString) : null
      const ChipContent = (id, type, name) => (
        <Chip
          avatar={
            <Avatar
              src={
                id
                  ? `${getTenantMainSite()}/${type}/profile-image/${id}`
                  : undefined
              }
            >
              {getInitials(name)}
            </Avatar>
          }
          label={name}
          size="small"
        />
      )

      return {
        scope:
          scopeConfig.General.Leads.VIEW && scopeConfig.General.Leads.REASSIGN,
        label: (
          <Typography sx={{ mr: 0.5 }} variant="body2">
            <b>Lead Reassign</b>
          </Typography>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              Assigned to{' '}
              {toId ? (
                <NextLink href={'/groups/' + toId + '?tab=details'} passHref>
                  {ChipContent(toId, 'group', toName)}
                </NextLink>
              ) : (
                ChipContent(toId, 'group', toName)
              )}
              by{' '}
              {byId ? (
                <NextLink href={'/members/' + byId + '?tab=details'} passHref>
                  {ChipContent(byId, 'member', byName)}
                </NextLink>
              ) : (
                ChipContent(byId, 'member', byName)
              )}
            </Typography>
          </Box>
        ),
      }
    case 'mortgage-calc':
      return {
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Mortgage Calculator</b>{' '}
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Card variant="outlined" sx={{ my: 1, userSelect: 'text' }}>
              <CardHeader
                avatar={
                  <Avatar
                    src={getPropertyImage(
                      activity.propertyListingId,
                      activity.photoCount,
                      activity.sourceSystemKey,
                      activity.propertyLatitude,
                      activity.propertyLongitude,
                    )}
                    variant="rounded"
                  />
                }
                title={
                  <Typography color="textPrimary">
                    {stringToTitle(
                      activity.data?.property?.unstructuredAddress,
                    )}
                  </Typography>
                }
                subheader={
                  <Typography color="textPrimary" variant="subtitle2">
                    {activity.data?.price}
                  </Typography>
                }
                sx={{
                  py: 2,
                  backgroundColor: 'background.default',
                }}
              />

              <List sx={{ backgroundColor: 'rgba(255, 255, 255, 0.04)' }}>
                {Object.entries(activity?.data)?.map(
                  (item: [string, string], index: number) =>
                    calcMap[item[0]] && (
                      <ListItem
                        key={index}
                        disableGutters
                        divider
                        sx={{
                          justifyContent: 'space-between',
                          padding: 1,
                          '&:last-child': { borderBottom: 'none', pb: 0 },
                        }}
                      >
                        <Typography variant="subtitle2">
                          {calcMap[item[0]]}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {item[1]}
                        </Typography>
                      </ListItem>
                    ),
                )}
              </List>
            </Card>
          </Box>
        ),
      }
    case 'update-user-details':
      return {
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Box sx={{ mr: 0.5 }} component="div">
            <b>Updated Info</b>
            <Typography color="textSecondary" variant="caption">
              {activity.firstName &&
                ` - ${activity.firstName} ${activity.lastName}`}
            </Typography>
          </Box>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="subtitle2">
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  flexGrow: 1,
                  p: 2,
                  my: 1,
                }}
              >
                Lead information updated{' '}
                <b>
                  {Object.entries(flattenObject(activity?.data?.diff)).map(
                    value => {
                      const keyParts = value[0].split('.')
                      const formattedKey = keyParts
                        .map((part, index) => {
                          // If it's the first part, make the first letter uppercase
                          if (index === 0) {
                            return part.charAt(0).toUpperCase() + part.slice(1)
                          } else {
                            // Otherwise, use normal case and add a dash before
                            return (
                              ' - ' +
                              part.charAt(0).toUpperCase() +
                              part.slice(1)
                            )
                          }
                        })
                        .join('')

                      const beforeValue =
                        typeof setByDotNotation(
                          activity?.data?.before,
                          value[0],
                        ) === 'boolean'
                          ? setByDotNotation(activity?.data?.before, value[0])
                            ? 'true'
                            : 'false'
                          : setByDotNotation(activity?.data?.before, value[0])

                      const afterValue =
                        typeof setByDotNotation(
                          activity?.data?.after,
                          value[0],
                        ) === 'boolean'
                          ? setByDotNotation(activity?.data?.after, value[0])
                            ? 'true'
                            : 'false'
                          : setByDotNotation(activity?.data?.after, value[0])

                      return (
                        <Typography sx={{ mr: 0.5 }} variant="subtitle2">
                          {formattedKey}: <b>{beforeValue}</b> -&gt;{' '}
                          <b>{afterValue}</b>
                        </Typography>
                      )
                    },
                  )}
                </b>{' '}
              </Box>
            </Typography>
          </Box>
        ),
      }
    case 'tcpa-set':
      return {
        scope: scopeConfig.General.Leads.VIEW,
        label: (
          <Typography sx={{ mr: 0.5 }} variant="body2">
            <b>TCPA Set</b>
          </Typography>
        ),
        component: (
          <Box>
            <Typography sx={{ mr: 0.5 }} variant="body2">
              <Typography color="textSecondary" variant="caption">
                {activity.firstName &&
                  ` - ${activity.firstName} ${activity.lastName}`}
              </Typography>
            </Typography>
          </Box>
        ),
      }
    default:
      return <Fragment></Fragment>
  }
}

interface RecentActivityProps {
  recentActivities: RecentActivityInterface[]
  leadDetail: LeadDetailResponse
  updateTab: (tab: string) => void
}
export const RecentActivity: FC<RecentActivityProps> = ({
  hideTitleHeader,
  recentActivities = [],
  updateTab,
  leadDetail,
  infiniteRef,
}) => {
  const user = useAuth(state => state.user)

  const router = useRouter()
  const filteredActivities = recentActivities?.filter(item =>
    user?.scopes?.includes(getActivityContent(item, user).scope),
  )

  return (
    <Card
      elevation={0}
      square={true}
      sx={{
        '& + &': {
          mt: 0,
        },
      }}
    >
      {!hideTitleHeader && leadDetail && (
        <>
          <CardHeader title="Recent Activity" sx={{ p: 2 }} />
          <Divider />
        </>
      )}

      <CardContent sx={{ py: 0 }}>
        <Timeline
          sx={{
            mb: 0,
            p: 0,
          }}
        >
          {filteredActivities.map((item, key) => {
            let lead = leadDetail
              ? leadDetail
              : {
                  firstname: item.firstName,
                  lastName: item.lastName,
                  id: item.leadId,
                  email: item.email,
                }
            const handleActivityClick = () => {
              const scroll_id =
                getActivityContent(item, lead, user).scrollId ?? ''
              if (updateTab) updateTab(tabsMap[item.type] ?? 'details')

              if (!leadDetail)
                if (scroll_id) {
                  router.push(
                    `/leads/${item?.leadId?.toString()}?tab=details#${scroll_id}`,
                  )
                } else {
                  router.push(`/leads/${item?.leadId?.toString()}?tab=details`)
                }
            }

            return (
              <TimelineItem
                ref={infiniteRef}
                key={`${item.id}_${key}`}
                sx={{
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot sx={{ border: 0, p: 0 }}>
                    <Avatar
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'common.white',
                        boxShadow: 3,
                        width: 50,
                        height: 50,
                      }}
                      src={getActivityContent(item, lead, user).avatarImg}
                      variant="rounded"
                    >
                      {getActivityIcon[item.type]}
                    </Avatar>
                  </TimelineDot>
                  {filteredActivities.length - 1 > key && (
                    <TimelineConnector
                      sx={{ backgroundColor: 'divider', minHeight: 30 }}
                    />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Link
                    underline="none"
                    component="button"
                    onClick={handleActivityClick}
                    color="textPrimary"
                  >
                    {getActivityContent(item, lead, user).label}
                  </Link>
                  {getActivityContent(item, lead, user).component}
                  <Link
                    underline="none"
                    component="button"
                    onClick={handleActivityClick}
                    color="textPrimary"
                  >
                    <Typography color="textSecondary" variant="caption">
                      {format(
                        parseISO(item.createdAt ?? new Date().toISOString()),
                        'dd MMM,yyyy hh:mm aaaa',
                      )}
                    </Typography>
                  </Link>
                </TimelineContent>
              </TimelineItem>
            )
          })}
        </Timeline>
      </CardContent>
      {!hideTitleHeader && leadDetail && <Divider />}
    </Card>
  )
}
