/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

export function flattenObject(
  ob: any,
  prefix: any = false,
  result: any = null,
) {
  result = result || {}

  // Preserve empty objects and arrays, they are lost otherwise
  if (
    prefix &&
    typeof ob === 'object' &&
    ob !== null &&
    Object.keys(ob).length === 0
  ) {
    result[prefix] = Array.isArray(ob) ? [] : {}
    return result
  }

  prefix = prefix ? `${prefix}.` : ''

  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      if (typeof ob[i] === 'object' && ob[i] !== null) {
        // Recursion on deeper objects
        flattenObject(ob[i], prefix + i, result)
      } else {
        result[prefix + i] = ob[i]
      }
    }
  }
  return result
}

export function unflattenObject(ob) {
  const result = {}
  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      const keys: any = i.match(/(?:^\.+)?(?:\.{2,}|[^.])+(?:\.+$)?/g) // Just a complicated regex to only match a single dot in the middle of the string
      keys.reduce(
        (r, e, j) =>
          r[e] ||
          (r[e] = Number.isNaN(Number(keys[j + 1]))
            ? keys.length - 1 === j
              ? ob[i]
              : {}
            : []),
        result,
      )
    }
  }
  return result
}
