import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { Box, LinearProgress } from '@mui/material'

import api from 'utils/api'

import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Scrollbar } from 'components/scrollbar'
import { RecentActivity } from 'components/lead-details/recent-activity'
import { useGlobal } from 'store/useGlobal'
import shallow from 'zustand/shallow'

export const NotificationData = ({
  notifications,
  resetNotifications,
  setNotifications,
}) => {
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const { accessLevel, setAccessLevel } = useGlobal(
    state => ({
      accessLevel: state.accessLevel,
      setAccessLevel: state.setAccessLevel,
    }),
    shallow,
  )
  const [pageRecord, setPageRecord] = useState(new Set<number>())

  const addPageRecord = (page: number) => {
    setPageRecord(previousState => {
      previousState.add(page)
      return new Set(previousState)
    })
  }
  const { isLoading, isFetching, status, isError, data, refetch } = useQuery(
    ['getAllNotifications', page],
    async () => {
      const result = await api.get(
        `/notifications?page=${page}&access=${accessLevel.value}`,
      )
      if (!pageRecord.has(page)) {
        if (page == 0) {
          setNotifications(result.data)
        } else {
          setNotifications(prev => [...prev, ...result.data])
        }
      }

      addPageRecord(page)

      if (result.data.length <= 0) {
        setHasMore(false)
      }

      // if (result.data.length > 0) {
      //   setNotifications(prev => prev.concat(result.data))
      //   setPage(page + 1)
      // } else {
      //   setHasMore(false)
      // }

      return result.data
    },
  )

  useEffect(() => {
    if (resetNotifications) {
      setPageRecord(new Set())

      setPage(0)
      setHasMore(true)
    }
  }, [resetNotifications])

  const fetchNext = () => {
    setPage(page + 1)
  }

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: fetchNext,
    disabled: false,
    rootMargin: '0px 0px 400px 0px',
  })

  return (
    <>
      <Scrollbar ref={rootRef}>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            minHeight: '100%',
          }}
        >
          {(isLoading || isFetching) && <LinearProgress sx={{ height: 2 }} />}

          <RecentActivity
            infiniteRef={infiniteRef}
            recentActivities={notifications}
          />

          {(isFetching || isLoading) && notifications.length > 0 && (
            <LinearProgress sx={{ height: 3 }} />
          )}
        </Box>
      </Scrollbar>
    </>
  )
}
