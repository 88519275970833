import { FC } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { X as XIcon } from 'icons/x'
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import { PLAN_MAPPING, PLANS } from 'lib/upgrade-plans'
import type { Plan } from 'lib/upgrade-plans'
import type { UpgradePath, UpgradeOption } from 'lib/features/featureFlags'

interface ProductSelectionDialogProps {
  open: boolean
  onClose: () => void
  upgradePath: UpgradePath
  onSelectProduct: (plan: Plan) => void
}

export const ProductSelectionDialog: FC<ProductSelectionDialogProps> = ({
  open,
  onClose,
  upgradePath,
  onSelectProduct,
}) => {
  const getPlanFromOption = (option: UpgradeOption): Plan | null => {
    const planKey = PLAN_MAPPING[option.name]
    return planKey ? PLANS[planKey] : null
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="inherit">
          Choose Your Upgrade
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <XIcon fontSize="small" />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {upgradePath.options.map(option => {
            const plan = getPlanFromOption(option)
            if (!plan) return null

            return (
              <Grid item xs={12} md={6} key={option.name}>
                <Card
                  variant="outlined"
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {option.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mb: 2 }}
                    >
                      {option.description}
                    </Typography>
                    <List dense>
                      {plan.features.slice(0, 5).map((feature, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <CheckCircleIcon color="primary" fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={feature.name}
                            secondary={feature.description}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                  <CardActions sx={{ p: 2, pt: 0 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => onSelectProduct(plan)}
                    >
                      Select {option.name}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
