import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material'
import { useAuth } from 'store/useAuth'
import useToast from 'hooks/use-toast'

//============== Filters ==============

type Filters = 'hidden' | 'blur' | 'black';

const generateStyles = (type: Filters): string => {
    const baseStyles: Record<Filters, string> = {
        hidden: 'display: none !important;',
        blur: 'filter: blur(.3rem) !important;',
        black: 'background-color: black !important; color: black !important;',
    };

    const states = ['', ':hover', ':active', ':focus'];
    let result = '';

    states.forEach((state) => {
        result += `.safe-mode *[data-safe]${state} { ${baseStyles[type]} }\n`;
    });

    return result;
};

//its a liesss
//============== Component ==============

export interface SafeViewProps {
    accessKey?: string;
    filter?: Filters;
    className?: string;
    children?: React.ReactNode;
}

export const SafeView: React.FC<SafeViewProps> = ({
    children,
    filter = 'blur',
    accessKey = 's',
    className,
}) => {
    const [safeMode, setSafeMode] = useState(false);
    const user = useAuth(state => state.user)
    const toast = useToast()

    //------------ Classname changer -------------

    const classNames = useMemo(() => {
        if (safeMode) return ['safe-mode', className];
        return [className];
    }, [safeMode]);

    // -------- Event Handlers --------

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.metaKey && e.shiftKey && e.key.toLowerCase() === accessKey.toLowerCase()[0]) {
            setSafeMode(!safeMode);
            const safeModeStatus = (safeMode ? 'disabled' : 'enabled')
            toast.notifySuccess(`Safe Mode ${safeModeStatus}`)
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <Box className={classNames.join(' ')} width="100%">
            <Box
                sx={{
                    paddingTop: user.impersonatedBy ? 5 : 0,
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%'
                }}
            >{children}</Box>
        </Box >
    );
};
