// config/upgrade-plans.ts
import type { Product, UpgradePath } from 'lib/features/featureFlags'
export interface Feature {
  name: string
  description: string
}

export interface Plan {
  id: string
  name: string
  addons?: string[]
  products?: Product[]
  features: Feature[]
  title: string
  subtitle: string
  description: string
  buttonText: string
  dontShowModalAgain: boolean
}
export const PLAN_TYPES = [
  'realtyPro',
  'realtySellerPro',
  'realtySellerProAddon',
  'epique',
] as const

export type PlanType = typeof PLAN_TYPES[number]

export const realtyProFeatures: Feature[] = [
  {
    name: 'Exclusive Lead Pool Opportunities',
    description: 'Claim premium leads from our exclusive pool.',
  },
  {
    name: 'Seller Home Value Nurture Campaigns',
    description:
      'Keep your clients engaged with personalized home value updates.',
  },
  {
    name: 'Buyer Market Statistics Reports',
    description: 'Access detailed market insights to guide your buyers.',
  },
  {
    name: 'Review Imports',
    description:
      'Seamlessly import reviews from Homes.com, Zillow, and Realtor.',
  },
  {
    name: 'Hyper-Local Blogging',
    description:
      'Leverage cutting-edge AI technology to enhance your local presence.',
  },
  {
    name: 'Customizable Service Areas',
    description:
      'Configure your service areas to focus on your preferred markets.',
  },
  {
    name: 'Nationwide search',
    description:
      'Provide your clients with access to properties across the country.',
  },
  {
    name: 'Integrations with Your Favorite CRM',
    description: 'Easily connect Realty Pro with your existing CRM tools.',
  },
]
export const PLAN_MAPPING = {
  'Realty Pro': 'realtyPro',
  'Realty Pro + Seller Pro Bundle': 'realtySellerPro',
  'Seller Pro Add-on': 'realtySellerProAddon',
  'Seller Pro + epique Bundle': 'epique',
} as const

function createPlans(): Record<PlanType, Plan> {
  const realtyPro: Plan = {
    id: 'realty-pro',
    name: 'basic',
    products: ['REALTY_PRO'],
    features: realtyProFeatures,
    title: 'Unlock the Full Potential of Realty Pro!',
    subtitle:
      'Unlock more features with Premium like AI Blogging, Reviews, Leads, and more.',
    description:
      'Thank you for claiming your profile! Elevate your real estate game by upgrading to Realty Pro and enjoy your first month free! Gain access to exclusive features designed to boost your success:',
    buttonText: 'Upgrade to Premium',
    dontShowModalAgain: false,
  }

  const realtySellerPro: Plan = {
    id: 'realty-seller-pro',
    name: 'basic',
    products: ['REALTY_PRO', 'SELLER_PRO'],
    addons: ['SPRO1'],
    features: [
      {
        name: 'Exclusive Seller Lead Opportunities',
        description:
          'Access multiple seller leads, giving you more chances to secure listings.',
      },
      {
        name: 'First-to-Claim Seller Lead Distribution System',
        description: 'Be the first to claim leads in a fast-paced environment.',
      },
      {
        name: 'Zero Referral Fees',
        description:
          'Keep 100% of your commissions without any referral deductions.',
      },
      ...realtyProFeatures,
    ],
    title: 'Realty Seller Pro: Your Competitive Edge for Seller Leads',
    subtitle:
      'Access multiple seller leads, giving you more chances to secure listings.',
    description:
      "In today's fast-paced market, the first agent to claim a lead often wins. With Realty Seller Pro, speed is everything. We've packed it with powerful features like a verified profile, expert coaching, and seller nurturing campaigns so you can lock down and convert seller leads faster than ever before. Stop chasing leads—start converting them with Realty Seller Pro.",
    buttonText: 'Upgrade to Seller Pro',
    dontShowModalAgain: true,
  }

  return {
    realtyPro,
    realtySellerPro,
    realtySellerProAddon: {
      ...realtySellerPro,
      title: realtySellerPro.title,
    },
    epique: {
      ...realtySellerPro,
      name: 'epique',
    },
  }
}

export const PLANS = createPlans()

export const DEFAULT_MULTI_UPGRADE_PLAN: Plan = {
  id: 'multi-upgrade',
  name: 'multi',
  title: 'Enhance Your Real Estate Business',
  subtitle: 'Choose from our premium products to boost your success',
  description:
    'Take your real estate business to the next level with our premium products. Select the perfect combination of tools and features to match your needs.',
  buttonText: 'View Upgrade Options',
  dontShowModalAgain: false,
  features: [], // Will be populated dynamically
} as const
