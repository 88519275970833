import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { X as XIcon } from '../../icons/x'

import type { FC, MutableRefObject } from 'react'
import { NotificationData } from './notifications-data'
import { StyledDrawerWrapper } from '../styled-drawer-wrapper'

interface NotificationDrawerProps {
  containerRef?: MutableRefObject<HTMLDivElement | undefined>
  open?: boolean
  onClose: () => void
}

export const NotificationDrawer: FC<NotificationDrawerProps> = ({
  onClose,
  open,
  containerRef,
  setNotifications,
  notifications,
  resetNotifications,
  ...other
}) => {
  const [text, setText] = useState<string>('')
  const content = true ? (
    <>
      <Box
        sx={{
          backgroundColor: 'background.paper',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: 'divider',
            backgroundColor: 'background.default',
            color: 'textPrimary',
            display: 'flex',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
            position: 'sticky',
            top: 0,
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
        >
          <Typography color="inherit" variant="h6">
            Notifications
          </Typography>

          <IconButton color="inherit" onClick={onClose}>
            <XIcon fontSize="small" />
          </IconButton>
        </Box>

        <NotificationData
          resetNotifications={resetNotifications}
          notifications={notifications}
          setNotifications={setNotifications}
        />
      </Box>
    </>
  ) : null

  return (
    <StyledDrawerWrapper
      anchor="right"
      elevation={12}
      ModalProps={{ container: containerRef?.current }}
      onClose={() => {
        onClose()
        setText('')
      }}
      open={open}
      SlideProps={{ container: containerRef?.current }}
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      {content}
    </StyledDrawerWrapper>
  )
}

NotificationDrawer.propTypes = {
  containerRef: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}
