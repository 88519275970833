import React from 'react'
import toast from 'react-hot-toast'
import { Transition } from 'react-transition-group'

import { Box, Button } from '@mui/material'
import { RecentActivity } from 'components/lead-details/recent-activity'

// import { RecentActivity } from './recent-activity-old'

const duration = 300

const defaultStyle: any = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles: any = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const useNotificationAlert = () => {
  const notify = activity => {
    return toast.custom(t => (
      <Transition in={t.visible ? true : false} timeout={duration}>
        {state => {
          return (
            <Box
              sx={{
                maxWidth: 650,
                border: 2,
                borderColor: 'success.light',
                backgroundColor: 'background.paper',
                display: 'flex',
              }}
            >
              <RecentActivity recentActivities={[activity]} />
              <Box>
                <Button onClick={e => toast.remove(t.id)}>Close</Button>
              </Box>
            </Box>
          )
        }}
      </Transition>
    ))
  }

  return { notify }
}
export default useNotificationAlert
