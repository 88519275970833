import type {
  FC,
  ReactNode,
} from 'react'

import type { ListProps } from '@mui/material'
import {
  List,
  ListSubheader,
} from '@mui/material'

import { DashboardSidebarItem } from './dashboard-sidebar-item'

interface Item {
  path?: string
  icon?: ReactNode
  chip?: ReactNode
  info?: ReactNode
  children?: Item[]
  title?: string
  id?: string
  disabled?: boolean
}

interface DashboardSidebarSectionProps extends ListProps {
  children: Item[]
  path: string
  title?: string
  id?: string
  onDisabledLinkClick?: () => void
}

const renderNavItems = ({
  depth = 0,
  children,
  path,
  id,
  onDisabledLinkClick,
}: {
  depth?: number
  children: Item[]
  path: string
  id?: string
  onDisabledLinkClick?: () => void
}): JSX.Element => (
  <List disablePadding data-onborda={id}>
    {children.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          depth,
          path,
          onDisabledLinkClick,
        }),
      [],
    )}
  </List>
)

const reduceChildRoutes = ({
  acc,
  item,
  depth,
  path,
  onDisabledLinkClick,
}: {
  acc: JSX.Element[]
  depth: number
  item: Item
  path: string
  onDisabledLinkClick?: () => void
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`
  const partialMatch = path.includes(item.path)
  const exactMatch = path?.split('?')[0] === item.path.split('?')[0]
  if (item.children) {
    acc.push(
      <DashboardSidebarItem
        active={partialMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        id={item.id}
        onDisabledLinkClick={onDisabledLinkClick}
        disabled={item.disabled}
      >
        {renderNavItems({
          depth: depth + 1,
          children: item.children,
          path,
          onDisabledLinkClick,
        })}
      </DashboardSidebarItem>,
    )
  } else {
    acc.push(
      <DashboardSidebarItem
        active={exactMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        id={item.id}
        onDisabledLinkClick={onDisabledLinkClick}
        disabled={item.disabled}
      />,
    )
  }

  return acc
}

export const DashboardSidebarSection: FC<
  DashboardSidebarSectionProps
> = props => {
  const { children, path, title, id, onDisabledLinkClick, ...other } = props
  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: theme =>
              theme.palette.mode === 'dark' ? 'neutral.400' : 'neutral.400',
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 2.5,
            ml: 4,
            textTransform: 'uppercase',
          }}
          data-onborda={id}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        children,
        path,
        id,
        onDisabledLinkClick,
      })}
    </List>
  )
}
