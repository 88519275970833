import { useMemo } from 'react'
import FeatureFlags, { type Subscription } from '../featureFlags'

export function useFeatureFlags(
  subscription: Subscription | null,
  brand: any | null,
) {
  return useMemo(() => {
    if (!subscription) {
      return null
    }
    return new FeatureFlags(subscription, brand)
  }, [subscription, brand])
}
