import { io } from 'socket.io-client'
import api from 'utils/api'
import create from 'zustand'
import { URL } from 'config'

let socketUrl = URL?.includes('localhost') ? `ws://${URL}` : `wss://${URL}`

async function refreshToken() {
  return api.get('/oauth/token')
}

export const initSocket = token => {
  const socket = io(socketUrl, {
    reconnectionAttempts: 10,
    transports: ['websocket'],
    path: '/api/v2/ws',
    auth: {
      token: token,
    },
  })

  return socket
}

// let socket = initSocket(localStorage.getItem('accessToken'));

export const useSocket = create((set: any) => ({
  socket: undefined,
  initialised: false,
  initSocket: token =>
    set((state: any) => {
      return {
        ...state,
        socket: initSocket(token),
      }
    }),
}))
