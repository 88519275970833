import React, { useEffect, useState } from 'react'

import { styled } from '@mui/material/styles'

import { Kbar } from '../common/kbar'
import { DashboardNavbar } from './dashboard-navbar'
import { DashboardSidebar } from './dashboard-sidebar'
import { Onborda, OnbordaProvider } from 'components/onborda'
import { SafeView } from 'components/safe-view'
import { profilePaidSteps } from 'lib/tour-steps/profile-paid'
import { profileFreeSteps } from 'lib/tour-steps/profile-free'
import * as Sentry from '@sentry/nextjs'

import type { FC, ReactNode } from 'react'
import { useAuth } from 'store/useAuth'

import { useRoot } from 'store/useRoot'
import { WelcomeDialog } from './welcome-dialog'
import { useRouter } from 'next/router'

import { useFeatureFlags } from 'lib/features/hooks/useFeatureFlags'
import { AppInstallDialog } from 'components/app-install-dialog'
import { getTenantName } from 'tenant'

interface DashboardLayoutProps {
  children?: ReactNode
}

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  minHeight: '100vh',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}))

export const DashboardLayout: FC<DashboardLayoutProps> = props => {
  const { children } = props
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)
  const root = useRoot()
  const user = useAuth(item => item.user)
  const isAuthenticated = useAuth(item => item.isAuthenticated)
  const flags = useFeatureFlags(user?.subscriptions, user?.brand)
  const router = useRouter()

  if (root.initialReg && router.pathname !== '/initial') {
    router.replace('/initial')
    setTimeout(() => {
      root.setInitialReg(false)
    }, 400)
  }
  let steps = null
  console.log('flags', flags?.hasRealtyPro())
  if (flags?.hasRealtyPro()) {
    steps = profilePaidSteps
  } else {
    steps = profileFreeSteps
  }

  useEffect(() => {
    if (user && isAuthenticated) {
      const tenant = getTenantName()
      Sentry.setUser({
        id: user.id,
        email: user.email,
        tenant: tenant,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [user, isAuthenticated])

  return (
    <>
      <Kbar>
        <OnbordaProvider>
          <Onborda steps={steps} shadowOpacity="0.5">
            <DashboardLayoutRoot>
              <SafeView>{children}</SafeView>
            </DashboardLayoutRoot>
            <DashboardNavbar
              onOpenSidebar={(): void => setIsSidebarOpen(true)}
            />
            <DashboardSidebar
              onClose={(): void => setIsSidebarOpen(false)}
              open={isSidebarOpen}
            />
          </Onborda>
          {/* {getTenantName() === 'realty' && <AppInstallDialog />} */}
        </OnbordaProvider>
      </Kbar>

      {/* <WelcomeDialog
        open={root.initialReg && router.pathname !== '/initial'}
        onClose={() => root.setInitialReg(false)}
      /> */}
    </>
  )
}
