import { useMemo } from 'react'

import { RealtyLogo } from 'components/logos'
import { useSettings } from 'store/useSettings'
import { getTenantName } from 'tenant'

import { styled } from '@mui/material/styles'

interface LogoProps {
  variant?: 'light' | 'primary'
}

const Logos = {
  realty: RealtyLogo,
  homescout: RealtyLogo,
}

export const Logo = styled((props: LogoProps) => {
  const { variant, ...other } = props
  const value = useSettings()

  const LogoComponent = useMemo(() => {
    const tenantName = getTenantName()
    const defaultLogo = 'realty'
    return Logos[tenantName] || Logos[defaultLogo]
  }, [])

  return <LogoComponent theme={value.theme} />
})``
