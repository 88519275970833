// profile-upgrade-dialog.tsx
import { FC, useState } from 'react'
import { useAuth } from 'store/useAuth'
import { useLocalStorage } from 'hooks/use-localstorage'
import { X as XIcon } from 'icons/x'
import { toast } from 'react-hot-toast'
import api from 'utils/api'

import {
  Avatar,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material'

interface Feature {
  name: string
  description: string
}

interface ProfileUpgradeDialogProps {
  open: boolean
  handleClose: () => void
  id?: string
  dontShowModalAgain?: boolean
  title?: string
  description?: string
  buttonText?: string
  plan?: {
    name: string
    addons: string[]
    features: Feature[]
  }
}

export const ProfileUpgradeDialog: FC<ProfileUpgradeDialogProps> = ({
  handleClose,
  open,
  id,
  dontShowModalAgain = true,
  title,
  description,
  buttonText,
  plan,
}) => {
  const [hostedPageUrl, setHostedPageUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()
  const [dontShowAgain, setDontShowAgain] = useLocalStorage<boolean>(
    `dontShowAgain-${id}`,
    false,
  )

  const handleDontShowAgain = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDontShowAgain(event.target.checked)
    if (event.target.checked) {
      handleClose()
    }
  }

  const handleUpgrade = async () => {
    if (hostedPageUrl) {
      window.open(hostedPageUrl, '_blank')
      return
    }

    setIsLoading(true)
    try {
      const response = await api.post('/agents/zoho-hosted-page', {
        member_id: user.id,
        plan: plan?.name,
        addons: plan?.addons,
      })
      setHostedPageUrl(response.data.hosted_page)
      window.open(response.data.hosted_page, '_blank')
    } catch (error) {
      toast.error('Error upgrading to premium')
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <XIcon fontSize="small" />
          </IconButton>
        </Typography>
        <Typography
          sx={{
            backgroundColor: 'background.default',
            borderRadius: 1,
            flexGrow: 1,
            mt: 1,
            p: 2,
          }}
          variant="body2"
        >
          {description}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List>
              {plan?.features?.map((feature, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                      <CheckCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={feature.name}
                    secondary={feature.description}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {dontShowModalAgain && (
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={handleDontShowAgain}
              />
            }
            label="Don't show again"
            sx={{ ml: 1 }}
          />
        )}
        <Box sx={{ flex: 1 }} />
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleUpgrade}
          variant="contained"
          color="primary"
        >
          {buttonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
