import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from '@mui/material'

import SubscriptionDebugView from 'lib/features/components/SubscriptionDebugView'

export const SubscriptionDebugDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Subscription Debug</DialogTitle>
      <DialogContent>
        <SubscriptionDebugView />
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
